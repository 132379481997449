@import '../../../scss/config';

.system-settings-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }

  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 12px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .system-tabs {
    margin-top: 50px;
    // min-height: 400px;
    .ant-tabs {
      .ant-tabs-nav {
        &::before {
          border: none;
        }
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            flex-wrap: wrap;
          }
        }
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff !important;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      color: #fff !important;
      background: #244e63 !important;
    }
    .radios-field-lang > div:first-child {
      flex-direction: column;
    }

    .invoice-settings {
      margin-top: 50px;
      h2 {
        margin-bottom: 20px;
        font-weight: 600;
      }
      span {
        font-size: 16px;
        font-weight: 500;
      }
      label {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .tax-settings {
      margin-top: 50px;
      .tax-settings-content {
        margin-top: 40px;
        margin-bottom: 30px;
        h3 {
          margin-bottom: 20px;
        }
      }
      .custom-shared-form {
        .text-field-label-wrap {
          margin-bottom: 20px;
          .label-p {
            margin-bottom: 10px !important;
            font-size: 16px;
          }
          .form-text-field {
            height: 50px !important;
          }
        }
      }
    }
    .payment-settings {
      margin-top: 50px;
      h2 {
        margin-bottom: 20px;
      }
      .add-payment-btn {
        background-color: #244e63;
        color: #fff;
        border-radius: 8px;
        height: 50px;
        font-weight: 500;
        font-size: 16px;
        /* letter-spacing: 1px; */
      }
      .payment-card {
        display: flex;
      }
      .payment-loading {
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .img-form-wrap {
    margin-top: 22px;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 52px;

    // & > img {
    //   align-self: center;
    // }
  }

  .settings-form {
    .btn {
      @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .form-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 12px;
      @include mobile {
        // grid-template-columns: 100%;
      }

      .building-number-street-name {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        @include mobile {
          align-items: end;
          justify-content: center;
        }
      }
      .country-city-region-wrap {
        grid-column: 1 / -1;
      }
      .settings-form-img-wrap {
        grid-column: 1 / -1;
      }

      .post-box-wrap1 {
        grid-column: 1 / -1;
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(3, 1fr);
        @include mobile {
          align-items: end;
          justify-content: center;
        }
      }
    }
    .triple-wrapper {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 22px;
      @include mobile {
        align-items: end;
        justify-content: center;
      }
    }
  }

  .fatoorah-types-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    gap: 12px 22px !important;
  }
}

.pos-count-wrap {
  // max-width: calc(2 * 392px);
  display: grid;
  justify-content: center;
  gap: 12px;
  font-size: 18px;
  .pos-label,
  .pos-price-wrap {
    margin-bottom: 4px;
    display: flex;
    gap: 4px;
  }
  .pos-number {
    color: $main-app-color;
    font-size: 20px;
    background-color: #fff;
    border-radius: 8px;
    padding: 4px 12px;
    padding-bottom: 8px;
    border: 1px solid rgba(#000, 0.1);
  }

  .btns-labels-wrap {
    background-color: #f7f8fb;
    padding: 24px 28px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;

    .plus-minus-wrap {
      display: flex;
      gap: 14px;
      align-items: center;

      .plus-btn,
      .minus-btn {
        background-color: #b5b5b5;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        cursor: pointer;

        span {
          font-size: 24px;
          margin-top: -8px;
          font-weight: bold;
        }
      }
    }
  }
}

.total-labels-values-wrap {
  margin-bottom: 32px;
  color: #7e8897;
  display: flex;
  justify-content: center;
  gap: 32px;
  align-items: center;
  .total-pos-wrap {
    display: flex;
    gap: 4px;
  }

  .labels-wrap,
  .values-wrap {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
.add-new-pos {
  h2 {
    margin: 25px;
    text-align: center;
  }
}
.add-pos-btn {
  background-color: #244e63 !important;
  color: #fff !important;
  border: none;
  font-weight: 600 !important;
  height: 50px !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.pos-packages {
  margin-top: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  .pos-packages-title {
    text-align: center;
    font-weight: 600;
    margin-bottom: 90px;
  }
  .package-card-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f7f8fb;
    border-radius: 20px;
    padding: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    position: relative;

    img {
      position: absolute;
      top: -70px;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin-bottom: 10px;
      border: 5px solid #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
    h2 {
      font-weight: 700;
      margin-top: 50px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
    h4 {
      font-weight: 600;
      margin-bottom: 10px;
    }
    .buy-btn {
      background-color: #244e63;
      color: #fff;
      width: 120px;
      height: 40px;
      font-weight: 600;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
  }
}
.error-p {
  color: lighten(red, 10%);
  font-size: 12px;
  text-align: right;
}
