.create-receipt-form {
  .prev-new-client-wrap {
    align-items: center;
    gap: 32px;
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .new-client-btn {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    & > svg {
      height: 52px;
      width: 52px;
    }
  }
}
.receipt-date-picker {
  width: 100%;
  background-color: #f6f5f8 !important;
  height: 62px;
  border-radius: 8px;
  input {
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}
