@import '../../../scss/config';

.store-item-manager-page {
  .top-section-wrap {
    display: grid;
    grid-template-columns: 50% 30% 20%;
    align-items: center;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .StoreItemManagerPage-table-wrapper {
    @include mobile {
      width: 95%;
      height: 550px;
      margin: 20px auto;
      overflow: scroll;
    }
  }

  .page-counts-wrap {
    margin: 32px 0;
    display: grid;
    .main-counts-ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
      li {
        max-width: 332px;
        background-color: $main-bg-color;
        padding: 12px 22px;
        min-height: 86px;
        border-radius: 22px;
        display: grid;
        grid-gap: 14px;
        grid-auto-flow: column;
        justify-content: space-between;
        .icon-wrap {
          align-self: center;
          background-color: #fff;
          padding: 8px 10px;
          border-radius: 12px;
          width: fit-content;
          display: grid;
          place-items: center;
          min-width: 52px;
          height: 60px;
        }

        .card-text {
          align-self: center;

          p {
            color: #8b8b8b;
            &:first-of-type {
              font-weight: bold;
              font-size: 18px;
              color: $main-app-color;
              margin-bottom: 2px;
            }
          }
        }

        .number-border-wrap {
          display: grid;
          font-size: 18px;
          position: relative;
          padding-bottom: 4px;
          height: 80%;
          top: 10%;
          .number-span {
            color: $main-app-color;
            font-weight: bold;
          }
          .border-span {
            background-color: #ddd;
            height: 2px;
            width: 140%;
            position: absolute;
            bottom: 2px;
            left: 0;
            border-radius: 99px;
          }
          .dark-border-span {
            position: absolute;
            bottom: 2px;
            left: 0;
            background-color: $main-app-color;
            height: 2px;
            width: 100%;
            border-radius: 99px;
          }
        }
      }
    }
  }
  .products-types{
    .section-text{
      margin-top: 30px;
    }
    margin-top: 30px;
    margin-bottom: 10px;
    // min-height: 400px;
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff !important;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      color: #fff !important;
      background: #244e63 !important;
    }
    .ant-radio-group-solid {
      @include mediaLgMax{
        display: grid;
        grid-template-columns: repeat(4,1fr);
      }
      @include mobile{
        grid-template-columns: repeat(3,1fr);
      }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fff;
      background: #244e63 !important;
      border-color: #244e63 !important;
      font-weight: 600;
    }
    .ant-radio-button-wrapper {
      position: relative;
      display: inline-block;
      height: 45px;
      margin: 0;
      padding: 0 18px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 43px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-top-width: 1.02px;
      border-left-width: 1.02px;
      cursor: pointer;
      transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;

      @include mediaLgMax{
        text-align: center;
      }
    }
    .radios-field-lang > div:first-child{
      flex-direction: column;
    }
  }


  .custom-filter-add-section{
    @include tablet{
      .form-excel-export-wrap{
        flex-wrap: nowrap;
      }

    }
    @include mid_tab{
      .preview-download-upload{
        padding: 5.5px 21px;
      }
    }
    @include mediaLgMax{

      .add-section{
        margin:  0;
      }
      .form-excel-export-wrap{
        flex-wrap: wrap;
        justify-content: flex-start !important;
    
        .filter-form{
          display: flex !important;
          flex-direction: row;
          flex-wrap: nowrap;
        }
      }
    }
 
  }





} 

