@import '../../../scss/config';

.archive-notes-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .add-section {
    margin-top: 22px;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-account-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
    }
  }
}
.sticky-modal {
  padding-bottom: 0 !important;

  .ant-modal-header {
    background-color: #f1f38d !important;
    border-bottom: 0 !important;
  }
  .ant-modal-content {
    border-radius: 5px !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .sticky-note-wrap {
    background-color: #fcff9c;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 432px;
    padding: 14px 22px;
  }
}
