@import '../../../scss/config';

.selected-product-modal {
  .product-details-wrap {
    display: grid;
    gap: 16px;
    @include mobile {
      width: 100%;
      img {
        width: auto;
        min-width: 150px;
        max-width: 200px;
      }
    }
    .ant-descriptions-rtl {
      @include mobile {
        width: 100%;
      }
      @include sm_mobile {
        width: 90%;
      }
    }
    .product-cell {
      display: grid;
      grid-template-columns: 182px 1fr;
      gap: 8px;
      align-items: center;
      padding-bottom: 22px;
      border-bottom: 1px solid rgba(#000, 0.1);

      .cell-title {
        font-size: 18px;
      }
      .cell-value:not(.cell-img) {
        background-color: #eee;
        width: fit-content;
        padding: 8px 12px;
        border-radius: 8px;
      }
      .cell-value {
        img {
          background-color: #eee;
          max-width: 100%;
          max-height: 442px;
        }
      }
    }
  }
}
