@import '../../scss/config';

.dropZone-wrapper {
  width: 90%;
  width: 100%;
  display: grid;
  justify-self: center;
  align-self: center;
  & > div {
    &.filled {
      // pointer-events: none;
    }
    &:focus {
      outline: none;
    }
  }
  .dropZone {
    cursor: pointer;
    background-color: #fff;
    // background-color: #151515;
    background-color: $main-bg-color;
    border: 2px dashed #bbb;
    border-radius: 4px;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 52px 22px;

    .placeholderText {
      display: grid;
      justify-items: center;
      i {
        font-size: 24px;
        color: #999;
      }
      p {
        margin-top: 4px;
        color: #888;
        @include bodyFont-ar;
      }
    }

    &.dragZone {
      border-color: lighten($main-bg-color, 20%);
      border-width: 3px;
      background-color: #eee;
    }
  }

  &.hideMe {
    display: none;
  }

  .dropzone-default-imgs-ul {
    display: grid;
    .default-dropzone-img {
      max-width: 400px;
      border: 1px solid rgba(#000, 0.1);
      border-radius: 4px;
      padding: 2px;
      cursor: pointer;
    }
  }

  .dropzone-camera {
    svg {
      width: 42px;
      height: 42px;
    }
  }
}
