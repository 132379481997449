@import '../../../scss/config';

.invoice-details-wrap-header {
  margin-bottom: 40px;
  .img-wrap {
    width: 60%;
    @include mobile {
      width: 100%;
    }
  }
  .invoices-data {
    width: 40%;
    @include mobile {
      width: 100%;
    }
  }
  @include mobile {
    display: flex !important;
    flex-direction: column;
  }
}

.invoice-details-wrap-header-table {
  .ant-descriptions-item-label {
    font-weight: 800;
  }
}
.supplier-details-wrap-billed {
  .supplier-details-wrap-billed-from {
    margin-bottom: 30px;
  }
  @include mobile {
    display: flex !important;
    flex-direction: column;
  }
}
.tax-supplier-details-wrap {
  .tax-supplier-details-wrap-total {
    .ant-descriptions-item-label {
      font-weight: 700;
    }
  }
}
.tax-invoices {
  margin-top: 30px;
  width: 100%;
  .tax-invoices-note {
    width: 50%;
  }
  .tax-invoices-prices {
    width: 50%;
  }
  @include mobile {
    display: flex !important;
    flex-direction: column;
  }
}
