@import '../../../scss/config';

.tax-invoice-reports-page {
  .custom-hidden-content {
    display: none;
  }
  .top-section-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
    h1 {
      font-size: 42px;
      @include tablet {
        align-self: flex-start;
      }
      @include mobile {
        font-size: 30px;
      }
      @include mediaMdMax{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .page-reports-filter {
    margin-top: 18px;
    .filter-form {
      display: flex;
      align-items: center;
      gap: 32px;

      @include mediaMdMax {
        flex-direction: column;
        text-align: center;
      }
      .form-btns {
        display: flex;
        gap: 12px;
      }
    }
  }

  .filter-range-picker {
    background-color: $main-bg-color;
    border-radius: 8px;
    border: 0;
    height: 44px;
  }
  .filter-submit-btn {
    background-color: $main-bg-color;
    border: 1px solid rgba(#000, 0.8);
    height: 36px;
    padding: 2px 28px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.1s ease-out;
    &:hover {
      background-color: $main-app-color;
      color: #fff;
    }
  }
}
