@import '../../../../scss/config';

.upgrade-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .page-body {
    margin-top: 32px;

    .upgrade-page-boxs-section {
      margin-top: 92px;
      .boxs-ul {
        display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(342px, 392px));
        // grid-template-columns: 1fr 1fr;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px 22px;
        // max-width: 892px;
        margin: auto;
        @include mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 70px;
        }
        .box-li {
          grid-column-end: span 1;
          // &:nth-of-type(1) {
          //   grid-column-start: 1;
          // }
          // &:nth-of-type(2) {
          //   grid-column-start: 1;
          // }
          // &:nth-of-type(3) {
          //   grid-column-start: 1;
          // }
          // &:nth-of-type(3),
          // &:nth-of-type(4) {
          //   grid-column-end: span 1;
          // }
          cursor: pointer;
          // max-width: 388px;
          height: calc(100% - 52px);
          display: grid;
          background-color: #f7f8fb;
          padding: 18px 22px;
          padding-top: 0;
          border-radius: 20px;
          .box-icon {
            justify-self: center;
            transform: translateY(-52px);
            border-radius: 50%;
            position: relative;
            background-color: #fff;
            padding: 4px;
            display: grid;
            place-items: center;

            .icon-wrap {
              display: grid;
              background-color: #f7f8fb;
              width: 88px;
              height: 88px;
              border-radius: 50%;
              display: grid;
              place-items: center;
            }

            svg {
              width: 42px;
              height: 42px;
              position: relative;
              z-index: 1;
            }
          }

          .box-rest-content {
            transform: translateY(-52px);
          }

          text-align: center;
          .name-price-wrap {
            color: $main-app-color;
            margin-top: 8px;
            font-weight: bold;
            font-size: 20px;
            display: flex;
            justify-content: center;
            gap: 5px;
          }

          .tax-p {
            font-size: 16px;
            margin: 4px 0;
          }
          .desc-p {
            font-size: 18px;
            line-height: 1.4;
            padding: 0 8px;
            margin-top: 8px;
            padding-bottom: 8px;
          }

          &.selected {
            background-color: $main-app-color;
            .box-icon {
              .icon-wrap {
                background-color: $main-app-color;
              }
              svg {
                path {
                  fill: #fff;
                }
              }
            }

            .name-price-wrap {
              color: rgba(#fff, 0.8);
            }

            .box-rest-content {
              color: rgba(#fff, 0.8);
            }
            .desc-p {
              color: rgba(#fff, 0.5);
            }
          }
        }
      }
    }

    .pos-count-wrap {
      // max-width: calc(2 * 392px);
      display: grid;
      justify-content: center;
      gap: 12px;
      font-size: 18px;
      .pos-label,
      .pos-price-wrap {
        margin-bottom: 4px;
        display: flex;
        gap: 4px;
      }
      .pos-number {
        color: $main-app-color;
        font-size: 20px;
        background-color: #fff;
        border-radius: 8px;
        padding: 4px 12px;
        padding-bottom: 8px;
        border: 1px solid rgba(#000, 0.1);
      }

      .btns-labels-wrap {
        background-color: #f7f8fb;
        padding: 24px 28px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        gap: 12px;
        width: fit-content;

        .plus-minus-wrap {
          display: flex;
          gap: 14px;
          align-items: center;

          .plus-btn,
          .minus-btn {
            background-color: #b5b5b5;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            cursor: pointer;

            span {
              font-size: 24px;
              margin-top: -8px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .total-labels-values-wrap {
      margin-bottom: 32px;
      color: #7e8897;
      display: flex;
      justify-content: center;
      gap: 32px;
      align-items: center;
      .total-pos-wrap {
        display: flex;
        gap: 4px;
      }

      .labels-wrap,
      .values-wrap {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    .continue-back-btns-wrap {
      // max-width: calc(2 * 392px);
      justify-content: center;
      margin-top: 22px;
      display: flex;
      gap: 22px;
      align-items: center;
    }
    .continue-btn,
    .back-btn {
      border: 0;
      outline: 0;
      background-color: $main-app-color;
      color: #fff;
      width: fit-content;
      padding: 8px 22px;
      padding-bottom: 12px;
      border-radius: 8px;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      gap: 8px;
      align-items: center;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .payment-selection {
      display: grid;
      gap: 22px;
    }

    .paytabs-section,
    .stcpay-section {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      max-width: 518px;
      background-color: #f7f8fa;
      border: 1px solid rgba(#000, 0.1);
      border-radius: 4px;
      @include mobile {
        display: flex;
        flex-direction: row;
      }
      .gateway-img {
        display: flex;
        flex-direction: row;
        padding: 0px 14px 0px 0px;
        background-color: #eee;
        @include mobile {
          flex-direction: column;
          align-items: center;
          padding: 0px;
        }
      }
      .gateway-imgs {
        padding: 8px 14px;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 12px;
        background-color: #f7f8fa;
        @include mobile {
          padding: 8px;
          // gap: 5px;
          &::-webkit-scrollbar {
            display: none;
          }
          // img {
          //   width: 30px;
          //   object-fit: cover;
          // }
        }
      }
      .circle-div-wrap {
        width: 22px;
        height: 22px;
        margin: 0 22px;
        border-radius: 50%;
        display: inline-block;
        display: grid;
        place-items: center;
        background-color: #bfbfbf;
        .circle-span {
          background-color: #fff;
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }

        &.selected {
          .circle-span {
            background-color: $main-app-color;
          }
        }
      }
    }

    .stcpay-section {
      opacity: 0.6;
      cursor: not-allowed;
      background-image: url('../../../../assets/imgs/bgs/architect.svg');
      background-repeat: repeat;
      background-size: 5%;
      background-position: center;
      img {
        opacity: 0.8;
      }
    }
  }

  &.expired {
    min-height: 100vh;
    margin: 32px 0;
  }

  .code-continue-btn-wrap {
    display: flex;
    justify-content: center;
    gap: 32px;

    & > label {
      display: grid;
      span {
        margin-right: 4px;
        margin-bottom: 6px;
        font-weight: bold;
        color: $main-app-color;
      }
      input {
        padding: 4px 18px;
        border: 1px solid rgba($main-app-color, 0.1);
        outline: 0;
        background-color: #eee;
        width: 140px;
        height: 52px;
        border-radius: 12px;
        font-size: 16px;
        font-weight: bold;
        transition: all 0.2s ease-out;
        &:focus {
          box-shadow: 0 0 2px rgba($main-app-color, 0.6);
        }
      }
    }
  }
}

.system-features{
  h2{
    // width: 170px;
    // height: 60px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-bottom: 20px;
    font-weight: 600;
    // background-color: $main-app-color;
    // color: #fff;
    // padding: 10px 20px;
    // border-radius: 10px;
    // -webkit-border-radius: 10px;
    // -moz-border-radius: 10px;
    // -ms-border-radius: 10px;
    // -o-border-radius: 10px;
}
  .all-features{
    display: flex;
    // align-items: center;
    gap: 20px;
    flex-direction: column;
    .no-features{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 250px;
    }
    .every-feature{
      display: flex;
      align-items: center;
      gap: 10px;
      img{
        border: 1px solid #f1f1f1;
        width: 35px;
        height: 35px;
      }
      div{
        display: flex;
        flex-direction: column;
      }
    }
  }
}