@import '../../../scss/config';

.print-simple-whole-receipt-wrap {
  // min-width: 382px;
  // max-width: 382px;
  // max-width: 532px;
  // justify-content: center;
  // width: 382px;

  .pos-receipt-wrap {
    background-color: #fff;
    border-radius: 10px;
    display: grid;
    grid-template-rows: auto 1fr;
    .store-logo-name-wrap {
      display: grid;
      justify-items: center;
      padding: 22px 22px 0 22px;
      img {
        max-width: 80%;
        width: 122px;
      }
      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 20px;
      }
    }

    .empty-cart-wrap {
      display: grid;
      place-content: center;
      padding: 22px;
      margin-bottom: 22px;
      p {
        margin-top: 12px;
      }
    }
    .invoice-content {
      .invoice-number {
        margin-top: 4px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
        justify-content: center;
        .number-span {
          background-color: #f5f5f5;
          padding: 4px 10px;
          padding-bottom: 6px;
          border-radius: 6px;
          font-size: 16px;
        }
      }

      .system-info {
        margin: 4px 12px;
        padding-bottom: 12px;
        margin-bottom: 0;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
        gap: 12px 4px;
        color: #000;
        font-size: 15px;
        // .cell-wrap {
        //   display: grid;
        //   grid-template-columns: auto 1fr;
        //   gap: 2px;
        // }
        .info-cell-value {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .date-time-wrap {
          display: flex;
          justify-content: space-between;
          .cell-wrap {
            margin-left: 28px;
          }
        }
      }

      .invoice-products-table {
        width: 100%;
        padding: 0 10px;
        /* text-align: right; */
        border-collapse: separate;
        border-spacing: 4px 12px;

        thead {
          tr > {
            th:first-of-type {
              // min-width: 136px;
            }
          }
        }
        .invoice-table-cell {
          // padding: 2px 12px;
        }
      }
      .prices-table {
        width: calc(100% - 8px);
        border-top: 1px dashed rgba(#000, 0.3);
        margin: auto;
        /* text-align: right; */
        border-collapse: separate;
        border-spacing: 4px 8px;
        .invoice-table-cell {
          padding: 2px 12px;
        }
        .col-head {
          min-width: 136px;
          // width: 194px;
        }
        .fake-td {
          color: #000;
          width: 75px;
        }
      }
    }

    .grand-total-invoice-price {
      border-top: 1px dashed rgba(#000, 0.3);
      color: #000;
      margin: 12px;
      margin-top: 0;
      padding-top: 8px;
      display: grid;
      font-size: 22px;
      text-align: center;
      span {
        &:nth-of-type(2) {
          margin-top: 4px;
        }
      }
    }

    .qr-code-img-wrap {
      display: grid;
      place-items: center;
      padding: 12px;
      padding-top: 0;
      margin-top: 12px;
      .qrcode-img {
        width: 192px;
        max-width: 100%;
      }
    }
  }
}
