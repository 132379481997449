@import '../../../scss/config';

.store-item-manager-page {
  .table-wrapper {
    @include mobile {
      width: 95%;
      margin: 0 auto;
      overflow: scroll;
    }
  }
}
