@import '../../scss/config';
.row-cell.row-circled-btn .bt-target {
  background-color: $main-app-color;
  min-height: 40px;
  padding: 8px 10px;
  color: $main-bg-color;

  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  border: 2px solid white;
  transition: 0.2s;
}
// .row-cell.row-circled-btn .bt-target:hover {
//   color: #40a9ff;
//   border-color: #40a9ff;
// }
