@import '../../../scss/config';

.store-ingredients-page {
  .table-wrapper {
    @include mobile {
      width: 96%;
      height: 550px;
      margin: 0 auto;
      overflow: scroll;
    }
  }
  .add-section-export-btn {
    margin-top: 22px;
    margin-bottom: 20px;
    display: flex;
    gap: 32px;
    align-items: center;
    @include mobile {
      margin-right: 20px;
    }
  }
  .page-title {
    @include tablet {
      align-self: flex-start;
    }
    @include mobile {
      margin-right: 20px;
    }
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 50% 30% 20%;
    align-items: center;
    grid-gap: 22px;

    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;
    }

    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .add-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-account-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
    }
  }
}

.ingredients-form {
  .cat-subcat-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
    @include mobile {
      width: 100%;
    }
  }
}
