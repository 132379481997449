@import '../../../scss/config';

.settings-categories-page {
  .page-title {
    order: 1;

    @include mediaMdMax{
      /* text-align: center;
      align-self: center; */
    }
    
    @media only screen and (min-width: 1025px) {
      order: 0;
    } 
    h2 {
      font-size: 42px;
      @include mediaLg {
        font-size: 42px;
      }
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    @include mediaLg {
      grid-template-columns: 2.9fr 1fr;
    }
    grid-gap: 22px;
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
