@import '../../../scss/config';

// .ant-table-tbody > tr > td{}

.settings-categories-page {
  @include mobile {
    margin: 0px auto;
  }

  .SharedOutgoingPurchaseInvoice-table-wrapper {
    @include mobile {
      width: 100%;
      height: 550px !important;
      overflow: scroll;
    }
    .ant-table-wrapper-rtl .ant-table table {
      @include mobile {
        width: 200px !important;
      }
    }
  }

  .ant-table-wrapper-rtl .ant-table table {
    overflow: scroll;
    @include mobile {
      width: 98% !important;
      margin: 0px !important;
      overflow: scroll;
    }
  }
  .antd-custom-table .row-cell.row-actions .edit-btn {
    @include mobile {
      font-size: 12px;
      padding: 1px;
    }
  }

  .ant-table-tbody > tr > td {
    @include mobile {
      width: 100px !important;
      padding: 0px !important;
      margin: 0px !important;
      overflow: scroll;
    }
  }

  .custom-table-fitler {
    @include tablet{
    }
    @include mobile {
      width: 100%;
      align-items: center;
    }
  }

  .custom-table-fitler
    .form-excel-export-wrap
    .filter-form
    .receipt-filter-option-wrap {
    @include mobile {
      width: 100%;
    }
    .ant-select {
      @include mobile {
        width: 90% !important;
      }
    }
  }
}

// .custom-table-fitler .form-excel-export-wrap .filter-form {
//   @include mob_tab {
//     width: 100%;
//     display: grid !important;
//     grid-template-columns: 50% 50%;
//     align-items: center;
//     .filter-submit-btn {
//       width: 150px;
//     }
//   }
// }
.custom-table-fitler .form-excel-export-wrap{
 
  @include mediaMdMax{
    display: grid !important;
    grid-template-columns:repeat(2,1fr) !important;
    grid-column-gap: 5px !important;
    margin-top: 20px;
    
    .filter-form{
      grid-column: 1/3;
      width: 100%;
    }
  }
  .filter-form{
    @include mediaLgMax{
       display: grid !important;
    }
    @include mobile{
         width: 100%;
         grid-template-columns: repeat(2,1fr) !important;
    }
    @include mediaLessSmd{
      grid-template-columns: 1fr !important;
    }
  }
 
}
.form-excel-export-wrap .range-picker-wrap {
  @include mobile {
    width: 90%;
  }
}
.store-Receipt-page {
  .page-title {
    h2 {
      @include mediaLg {
        font-size: 42px;
      }
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
.OutgoingPurchaseInvoicePage-page-head-wrap {
 /*  display: grid;
  grid-template-columns: 1fr 1.9fr;
  grid-gap: 22px; */
  @include tablet {
    display: flex;
    flex-direction: column;
    // text-align: center;
    // justify-content: center;
  }
  .search-accounts-wrap {
    display: grid;
    align-content: space-between;
  }
}
.add-section{
  @media only screen and (max-width: 400px){
    display: block !important;
    width: 60%;
  }
}