@import '../../../scss/config';

.archive-docs-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .add-section {
    margin-top: 22px;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .Archive-add-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
      @include mobile {
        width: max-content;
        padding: 5px 20px;
        height: 40px;
      }
    }
  }
}

.upload-doc-wrap {
  grid-column: 1 / -1;
  .title-input-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 22px;
  }
  .upload-file-label {
    cursor: pointer;
    line-height: 1;
    background-color: #efefef;
    border-radius: 5px;
    padding: 12px 16px;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;

    input {
      display: none !important;
    }

    .icon-text {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}
