@import '../../../scss/config';

.settings-form {
  .slogan-types-wrap {
    textarea {
      min-height: 100px;
      max-height: 200px;
    }
  }

  .first {
    @include mobile {
      grid-template-columns: 1fr !important;
    }
  }
  .form-body {
    @include mobile {
      align-items: flex-end !important;
    }
    .text-field-label-wrap {
      @include mobile {
        margin-top: 10px;
      }
      .selector-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 45% 45%;
        align-items: center;
        justify-content: flex-start;
        gap: 10%;
        @include mobile {
          grid-template-columns: 100%;
          margin-bottom: 20px;
        }
        select {
          width: 100%;
          padding-right: 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          // max-width: 50%;
          &:focus {
            border: 1px solid #c2c8d0;
            box-shadow: none;
            outline: none;
          }
        }
      }

      .label-p {
        @include mobile {
          display: flex;
          flex-direction: column;
        }
        .activity-span {
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
}
