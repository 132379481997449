@import '../../../scss/config';

// .summary-page{
//     .table-row-light thead {
//     background-color: red !important; 
//     color: red !important;
// }
// }

.level-1{
    color: #03033d;
    font-size: 17px !important
}
.level-2{
    color: #460e0e;
    font-size: 17px !important
}
.level-3{
    color: #0e410e;
    font-size: 17px !important
}
.level-4{
    color: rgb(69, 53, 13);
    font-size: 17px !important
}
.level-5{
    color: rgb(107, 107, 6);
    font-size: 17px !important
}
.level-5{
    color: rgb(73, 0, 73);
    font-size: 17px !important
}
.level-final{
    color: red;
    font-size: 20px
}
.ant-table-content{
    overflow-x: scroll ;
}
.invoice-details-wrap .ant-table-content{
    overflow-x : unset !important;
}