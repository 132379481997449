@import '../../scss/config';

.measurement-units-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .add-section {
    margin-top: 22px;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-account-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
    }
  }
  .no-chat-cont{
    padding: 15px 0;
    .no-chat{
      height: 70px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border: 1px solid #e8e8e8;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #2e4652;
      background-color: #f9f9f9;
      font-weight: 500;

    img{
      margin-left: 10px;
      margin-top: 4px;
    }
  }
  }
  
}

.chat-box{
  // background-image: url('../../assets/imgs/cover.jpg');
  margin-top: 20px;
  padding: 20px 20px 0px;
  // width: 90%;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 3px 0px #c2c2c2;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  
}
.chat-box-content{
  margin-bottom: 20px;
  padding: 0px 0px 20px;
  border-bottom: 1px solid grey;
}
.message-shared-form{
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;

  input{
    height: 60px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .submit-btn{
    background-color: $main-app-color;
    height: 60px;
    border-radius: 10px;
    border: none !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

}