@import '../../../scss/config';

.invoice-details-wrap {
  .invoice-heading-wrap {
    @include mobile {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .qr-code-label-wrap {
    @include mobile {
      align-self: center !important;
    }
  }
  .receipt_table{
    .ant-table-thead{
      .ant-table-cell{
        font-weight: 600 !important;
      }
    }
    .ant-table-cell{
      padding: 20px !important;
    }
  }
}
