@import '../../../scss/config';

.system-settings-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 12px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .img-form-wrap {
    margin-top: 22px;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: 52px;

    // & > img {
    //   align-self: center;
    // }
  }

  .settings-form {
    .btn {
      @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .form-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 12px;
      @include mobile {
        // grid-template-columns: 100%;
      }

      .building-number-street-name {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        @include mobile {
          align-items: end;
          justify-content: center;
        }
      }
      .country-city-region-wrap {
        grid-column: 1 / -1;
      }
      .settings-form-img-wrap {
        grid-column: 1 / -1;
      }

      .post-box-wrap1 {
        grid-column: 1 / -1;
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(3, 1fr);
        @include mobile {
          align-items: end;
          justify-content: center;
        }
      }
    }
    .triple-wrapper {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 22px;
      @include mobile {
        align-items: end;
        justify-content: center;
      }
    }
  }

  .fatoorah-types-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    gap: 12px 22px !important;
  }
}
