@import '../../../scss/config';

.accounting-details-page {
  .page-spin-wrap {
    min-height: 220px;
    padding: 52px;
    display: grid;
    place-items: center;
  }
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .add-section {
    margin-top: 22px;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-account-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
    }
  }

  .accounting-tree {
    margin-top: 32px;
    // padding: 28px 34px;
    // background-color: #f5f5f5;
    // border-radius: 8px;
    // & > ul {
    //   & > li {
    //     border-right-width: 0 !important;
    //   }
    // }
  }

  .leaf-name {
    font-size: 18px;
  }

  .ant-tree-title {
    min-height: 52px;
    display: inline-block;
    button {
      min-height: 52px;
      & > span {
        padding-bottom: 5px;
        color: $main-app-color;
      }
    }
  }
  .ant-tree .ant-tree-treenode {
    min-height: 52px;
    padding-bottom: 12px;
    .anticon {
      svg {
        width: 28px;
        height: 28px;
        color: $main-app-color;
      }
    }
  }
  .ant-tree-switcher {
    height: max-content;
    min-width: 52px;
    padding-bottom: 4px;
  }

  // .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  //   border-width: 2px;
  //   border-color: $main-app-color;
  //   // left: -25px;
  //   left: auto;
  // }

  .node-name-action-btns {
    display: grid;
    width: 100%;
  }
  .name-btns-wrap {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    width: 100%;
  }
  .name-btns-wrap {
    .action-btns {
      display: flex;
      align-items: center;
      gap: 12px;

      .circled-btn-wrap {
        button {
          height: 42px;
          width: 42px;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .add-btn,
      .reports-btn,
      .edit-btn {
        background-color: #b5b5b5;
        color: #222;
        background-color: $main-app-color;
        color: #fff;
        padding: 7px 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-size: 15px;
        svg {
          width: 16px;
          height: 16px;
        }
        .btn-text {
          padding-bottom: 4px;
        }
      }

      .add-btn {
        background-color: $main-app-color;
        color: #fff;
      }
      .delete-btn {
        background-color: $main-app-color;
        padding: 10px 7px;
        border-radius: 12px;
        display: grid;
        place-items: center;
        cursor: pointer;
        svg {
          width: 18px;
          height: 14px;
        }
      }
    }
  }
}
