@import '../../scss/config';

.pos-products-wrap {
  // margin-top: 14px;
  display: grid;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 8px;
  max-height: calc(100vh - 132px);
  overflow-y: auto;
  @include custom-scrollbar;

  .products-ul {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    @include mediaLLg {
      grid-template-columns: repeat(5, 1fr);
    }
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    .product-li {
      background-color: #fff;
      border-radius: 10px;
      padding: 8px 6px;
      padding-top: 22px;
      color: $main-app-color;
      display: grid;
      grid-template-rows: 1fr auto;
      border: 1px solid rgba(#000, 0.07);
      position: relative;
      overflow: hidden;

      .p-sale-price {
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        background-color: rgba($main-app-color, 0.98);
        color: #fff;
        display: grid;
        padding: 6px 12px;
        border-radius: 4px 0 0 18px;
        // height: 62px;
      }

      .cart-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 52px;
        height: 52px;
        // @include mediaXXlg {
        //   width: 68px;
        //   height: 68px;
        // }
        background-color: rgba(#fff, 0.94);
        padding: 8px 12px;
        border-bottom-right-radius: 18px;
        display: grid;
        place-items: center;
        cursor: pointer;
        img {
          width: 32px;
          // @include mediaXXlg {
          //   width: 48px;
          // }
        }
      }

      .not-available-tag {
        position: absolute;
        top: 0;
        left: 0;
        width: 72px;
        background-color: #d13b3b;
        padding: 6px 8px 9px 8px;
        border-radius: 0 4px 4px 0;
        color: #fff;
        font-size: 14px;
      }

      .p-img {
        display: grid;
        place-items: center;
        min-height: 92px;
        overflow: hidden;
        img {
          // min-width: 132px;
          width: 100%;
          max-height: 120px;
          object-fit: cover;
          object-position: center center;
        }
      }

      .p-name {
        font-size: 16px;
        text-align: center;
        margin: 2px;
        color: $main-app-color;
        padding-bottom: 4px;
        margin-bottom: 4px;
        // border-bottom: 1px dashed rgba(#000, 0.3);
      }

      .p-quantity-wrap {
        // border-bottom: 1px dashed rgba(#000, 0.3);
        display: grid;
        gap: 5px;
        grid-template-columns: auto 48px auto;
        // @include mediaXlg {
        //   grid-template-columns: auto 68px auto;
        // }
        // @include mediaXXlg {
        //   grid-template-columns: auto 78px auto;
        // }
        justify-content: center;
        align-items: center;
        min-height: 52px;
        border-top: 1px dashed rgba(#000, 0.3);
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input {
          height: 30px;
          // @include mediaXlg {
          //   height: 44px;
          // }
          // @include mediaXXXlg {
          //   height: 48px;
          // }
          border: 1px solid rgba(#000, 0.1);
          border-radius: 8px;
          background-color: #f5f5f5;
          padding: 4px 2px;
          padding-bottom: 10px;
          text-align: center;
          outline: 0;
          font-size: 18px;
          direction: ltr;
          color: $main-app-color;
          &:focus {
            border-color: $main-app-color;
          }
        }

        .add-btn,
        .sub-btn {
          display: grid;
          place-items: center;
          background-color: #f5f5f5;
          width: 28px;
          height: 28px;
          // @include mediaXlg {
          //   width: 42px;
          //   height: 42px;
          // }
          // @include mediaXXlg {
          //   width: 48px;
          //   height: 48px;
          // }
          border: 1px solid rgba(#000, 0.08);
          border-radius: 50%;
          cursor: pointer;

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }

        &.add-to-cart-wrap {
          grid-template-columns: 1fr;
          .btn-it-self {
            display: flex;
            place-content: center;
            align-items: center;
            gap: 8px;
            background-color: #f1f1f1;
            color: $main-app-color;
            padding: 4px 5px;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid rgba(#000, 0.04);
            transition: all 0.2s ease-out;
            &:hover {
              border-color: rgba(#000, 0.1);
            }
            img {
              height: 28px;
            }

            &.disabled {
              opacity: 0.6;
              cursor: not-allowed;
              background-image: url('../../assets/imgs/bgs/architect.svg');
              background-repeat: repeat;
              background-size: contain;
              background-position: center;
            }
          }
        }
      }

      .cart-btns-wrap {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;

        .p-discount-btn {
          background-color: $main-app-color;
          color: #fff;
          padding: 0px 4px 5px 4px !important;
          border-radius: 8px;
          cursor: pointer;
          border: 1px solid rgba(#000, 0.04);
          transition: all 0.2s ease-out;

          &.not-allowed {
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid rgba(#000, 0.1);
            // padding: 12px 14px;
            text-align: center;
            opacity: 0.6;
            cursor: not-allowed;
            background-image: url('../../assets/imgs/bgs/architect.svg');
            background-color: $main-app-color;
            color: #eee;
            background-repeat: repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }

      .ingredients-placeholder {
        width: 100%;
        padding: 4px 14px;

        &.p-ingredient-btn {
          background-color: $main-app-color;
          color: #fff;
          // padding: 12px 14px;
          border-radius: 8px;
          cursor: pointer;
          border: 1px solid rgba(#000, 0.04);
          transition: all 0.2s ease-out;
        }
        &.no-ingredients-tag {
          border-radius: 8px;
          cursor: pointer;
          border: 1px solid rgba(#000, 0.1);
          // padding: 12px 14px;
          text-align: center;
          opacity: 0.6;
          cursor: not-allowed;
          background-image: url('../../assets/imgs/bgs/architect.svg');
          background-color: $main-app-color;
          color: #eee;
          background-repeat: repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
    &.window {
      grid-template-columns: repeat(3, 1fr);
      @include mediaLLg {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    &.stacked {
      grid-template-columns: 1fr !important;
      .product-li {
        grid-template-rows: unset;
        grid-template-columns: auto 1fr;
        gap: 22px;
        .name-quantity-wrap {
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          justify-content: center;
          margin-left: 42px;
          .p-name {
            border-bottom: 0;
          }
          .p-name-empty {
            border-bottom: 0;
            text-align: center;
            color: #d13b3b;
          }
        }
      }
    }
  }

  position: relative;
  .products-change-appearance {
    position: fixed;
    bottom: 32px;
    left: 32px;
    z-index: 1;
    .btn-icon-wrap {
      background-color: $main-app-color;
      box-shadow: 0 8px 12px rgba(#000, 0.1);
      color: #fff;
      width: 52px;
      height: 52px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .return-q-wrapper {
    text-align: center;
    // border-bottom: 1px dashed rgba(#000, 0.3);
    margin: 0 12px;
    padding-bottom: 8px;
    p {
      margin-bottom: 4px;
    }
    input {
      height: 34px;
      width: 82px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background-color: #f5f5f5;
      padding: 4px 2px;
      padding-bottom: 10px;
      text-align: center;
      outline: 0;
      font-size: 18px;
      direction: ltr;
      color: #2e4652;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
