@import '../../../scss/config';


.custom-table-fitler-new{
    .form-excel-export-wrap{
        .filter-form{
              @include mobTab{
                display: flex !important;
                flex-direction: row !important;
                flex-wrap:nowrap !important;
              }
        }
    }
}
