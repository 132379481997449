@import '../../../scss/config';

.store-item-manager-page {
  max-width: 100%;
  margin: 0 auto;
  .top-section-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .table-wrapper {
    @include mobile {
      width: 100%;
      height: 550px !important;
      overflow: scroll;
      .ant-table-wrapper-rtl .ant-table table {
        @include mobile {
         // width: 200px !important;
        }
      }
    }
  }

  .page-counts-wrap {
    margin: 32px 0;
    display: grid;
    .main-counts-ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
      li {
        max-width: 332px;
        background-color: $main-bg-color;
        padding: 12px 22px;
        min-height: 86px;
        border-radius: 22px;
        display: grid;
        grid-gap: 14px;
        grid-auto-flow: column;
        justify-content: space-between;
        .icon-wrap {
          align-self: center;
          background-color: #fff;
          padding: 8px 10px;
          border-radius: 12px;
          width: fit-content;
          display: grid;
          place-items: center;
          min-width: 52px;
          height: 60px;
        }

        .card-text {
          align-self: center;

          p {
            color: #8b8b8b;
            &:first-of-type {
              font-weight: bold;
              font-size: 18px;
              color: $main-app-color;
              margin-bottom: 2px;
            }
          }
        }

        .number-border-wrap {
          display: grid;
          font-size: 18px;
          position: relative;
          padding-bottom: 4px;
          height: 80%;
          top: 10%;
          .number-span {
            color: $main-app-color;
            font-weight: bold;
          }
          .border-span {
            background-color: #ddd;
            height: 2px;
            width: 140%;
            position: absolute;
            bottom: 2px;
            left: 0;
            border-radius: 99px;
          }
          .dark-border-span {
            position: absolute;
            bottom: 2px;
            left: 0;
            background-color: $main-app-color;
            height: 2px;
            width: 100%;
            border-radius: 99px;
          }
        }
      }
    }
  }
}
