
@import '../../../scss/config';
.custom-filter-add-section{

    .stocking  .form-excel-export-wrap{

        @include mediaMdMax{
            display: block !important;
        
          .filter-form{
              display: block !important;
              margin: auto;
             }  
        }

        @include mediaLgMax{
            width: 300px;
            .filter-form{
                display: block !important;


                .entries-wrap{
                    .select-title{
                        @include mediaLgMax{
                            width: 40%;
                            text-align: center;
                        }
                      
                    }
                  }
            }
        }

       


      }
}
