@import '../../scss/config';

.login-form {
  width: 100%;
  display: grid;
  height: max-content;
  border: 5px solid #fff;
  max-width: 532px;
  border-radius: 10px;
  align-self: center;
  padding: 52px 0 !important;

  .form-header {
    .form-logo {
      display: grid;
      padding: 32px;
      padding-bottom: 8px;
      place-items: center;
      img {
        max-width: 100%;
        height: 52px;
      }
    }
    p {
      text-align: center;
      color: #888;
      font-size: 20px;
      margin-bottom: 22px;
      max-width: 80%;
      margin: auto;
      margin-bottom: 18px;

      &.main-title {
        color: #2e4652;
        font-size: 32px;
        margin-bottom: 6px;
      }
    }
  }

  .form-body {
    padding: 18px 16px;
    @include mediaLg {
      padding: 22px 32px;
    }
    display: grid;
    grid-gap: 12px;
    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper:hover {
      border-radius: 8px;
      height: 60px;
      background-color: #f5f5f5;
      border: 0;
      box-shadow: none;
      border-right: 9px solid #2e4652 !important;
      input {
        background-color: #f5f5f5;
      }
    }
    .ant-input-affix-wrapper-rtl .ant-input-prefix {
      margin: 0 0 0 12px;
    }

    .ant-checkbox-wrapper {
      color: #777;
      font-size: 16px;
    }

    .login-password-field-wrap {
      position: relative;
      &.with-eye-icon {
        input {
          padding-left: 42px !important;
        }
      }
      .eye-icon-btn {
        position: absolute;
        left: 14px;
        top: 13px;
        cursor: pointer;
        padding: 4px;
        z-index: 1;
        img {
          height: 18px;
        }
      }
    }

    .submit-btn {
      margin-top: 12px;
      cursor: pointer;
      outline: 0;
      border: 0;
      color: #fff;
      padding: 8px 62px;
      height: 50px;
      border-radius: 8px;
      background-color: #2e4652;
      width: fit-content;
      margin: auto;
    }
  }
  .forget-p {
    text-decoration: underline;
    cursor: pointer;
    color: $main-app-color;
    margin-top: -12px;
    margin-bottom: 12px;
  }
}
