@import '../../scss/config';

.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: #f6f5f8;
  border-radius: 20px;
  // min-height: 125px;
  // max-height: 150px;
  padding: 15px 10px;
  gap: 10px;
  direction: ltr;
  // width: 50%;
  // margin: 0 auto;

  overflow: hidden;
  @include tablet {
    width: 95%;
    // min-height: 100px;
    // max-height: 124px;
  }
  @include mobile {
    width: 95%;
    // min-height: 90px;
    // max-height: 114px;
    margin: 0 auto;
  }

  .time-end {
    font-family: 'DINNextLTArabic-Bold';
    font-size: 20px;
    align-items: center;
    justify-content: center;
    color: #2e4652;
  }
  .time-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    gap: 5px;
    border: 2px solid #2e4652;
    border-radius: 20px;
    padding: 10px;
    // background-color: #f6f5f8;

    .title {
      padding: 0 10px;
      width: auto;
      text-align: center;
      font-family: 'DINNextLTArabic-Bold';
    }

    @include mobile {
      width: 100%;
    }
    .timer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: rgba(255, 255, 255, 0.1);

      // display: inline-block;
      // padding: 10px;
      text-align: center;
      @include mobile {
        height: 60px;
        width: 100%;
      }
      .colon {
        height: 80px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include mobile {
          height: 100%;
        }
        p {
          font-size: 30px;
          font-weight: bold;
          color: #141d22;
          @include mobile {
            font-size: 20px;
          }
        }
      }
    }
    .col-4 {
      width: 80px;
      float: left;
      // height: 50px;
      @include mobile {
        width: 25%;
      }
    }
    .box {
      // border-right: solid 1px rgba(46, 70, 82, 0.2);
      font-weight: 300;
      padding: 5px 0;
      // background-color: #fff;
      // border-radius: 4px;
      // box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
      //   inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
    }
    .col-4:last-child .box {
      border-right-color: transparent;
    }
    .box p {
      letter-spacing: 3px;
      font-size: calc(18px + 0.7vmin);
      margin: 0;
      color: #2e4652;

      @include mobile {
        font-size: calc(15px + 0.7vmin);
      }
    }
    .text {
      font-size: 18px;
      font-family: 'DINNextLTArabic-Bold';
      color: #515151;
      font-weight: 600;
      @include mobile {
        font-size: 15px;
      }
    }
    p {
      font-family: 'DINNextLTArabic-Bold';
      font-size: 15px;
      align-items: center;
      justify-content: center;
    }
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #2e4652;
    color: #fff;
    border-radius: 8px;
    padding: 10px 32px;
    font-size: 13px;
    min-width: 132px;
    place-items: center;
    cursor: pointer;
    @include mobile {
      height: 25px;
    }
    @include mobile {
      font-size: 12px;
    }
  }
}
