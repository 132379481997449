@import '../../scss/config';

.shifts-page {
  .table-wrapper {
    @include mobile {
      width: 95%;
      height: 550px;
      margin: 0 auto;
      overflow: scroll;
    }

    @include sm_mobile {
      width: 90%;
      height: 550px;
      margin: 0 auto;
      overflow: scroll;
    }
  }

  .top-section-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
