#recaptcha-container {
  display: none;
}
@import './config';
@import './common';
@import './modals';

html {
  scroll-behavior: smooth;
  color: $main-text-color;

  &[lang='ar'] {
    body {
      @include bodyFont-ar;
      button {
        @include bodyFont-ar;
      }
    }
  }
  &[lang='en'] {
    body {
      @include bodyFont-ar;
      button {
        @include bodyFont-ar;
      }
    }
  }

  // &[lang="en"] {
  // 	@include bodyFont-en;
  // 	button {
  // 		@include bodyFont-en;
  // 		font-weight: bold;
  // 	}
  // }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  @include custom-scrollbar;
  background-color: #fff;
}

.app {
  min-height: 100vh;
  // background-color: #f5f5f5;
  // background-color: #f7f8fb;
  background-color: #fff;
  // display: grid;
  grid-template-rows: auto 1fr;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @import "./css/fontello.css";
  // @import "./css/ionicons.css";
}

[contenteditable='true'] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}

.notifications-component {
  &.rtl {
    .notification__close {
      right: auto;
      left: 10px;
    }
  }
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: center;
}
.ant-table-tbody > tr > td,
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: center;
  white-space:  nowrap !important;
  @include mobile {
    padding: 7px !important;
  }
  @include tablet {
    padding: 12px;
  }
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #595f6f;
  color: #fff;
  &::before {
    background-color: #bbb !important;
    left: 0;
    right: auto !important;
  }
}


.antd-custom-table {
  table {
    border-spacing: 0 22px !important;
  }

  .ant-table-thead {
    & > tr > th {
      background-color: $main-app-color;
      color: #bbb;
    }
    & > tr:first-child th:first-child {
      border-radius: 0 12px 12px 0;
    }
    & > tr:first-child th:last-child {
      border-radius: 12px 0 0 12px;
    }
  }
  tbody {
    & > tr > td {
      border-bottom: 0;
      background-color: $main-bg-color;
      margin-bottom: 12px;
      &:first-child {
        border-radius: 0 12px 12px 0;
      }
      &:last-child {
        border-radius: 12px 0 0 12px;
      }
    }
  }
  .has-read-more {
    color: #8b8b8b;
    font-size: 13px;
    display: flex;
    align-items: center;
    .read-more-span {
      padding-bottom: 4px;
    }
  }
  .ltr-text {
    direction: ltr !important;
  }
 
  .row-cell {
    // width: 132px;
    // @include mobile {
    //   width: 10px;
    // }
    display: grid;
    justify-content: center;
    &.row-index {
      // width: 82px;
      span {
        color: $main-app-color;
        background-color: #fff;
        font-size: 18px;
        border-radius: 10px;
        padding: 14px 10px;
        // min-width: 52px;
        min-height: 60px;
        display: grid;
        place-items: center;
        justify-content: center;
      }
    }

    &.row-title {
      display: grid;
      .label-span {
        color: $main-app-color;
        text-align: start;
      }
    }
    &.row-text {
      color: #8b8b8b;
      text-align: center;
    }
    &.row-img {
      img {
        cursor: pointer;
        width: 132px;
        max-height: 92px;
        object-fit: cover;
        object-position: top center;
        border-radius: 4px;
      }
    }
    &.row-icon {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &.row-link {
      a {
        background-color: transparent;
        color: $main-app-color;
        border: 1px solid #000;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 14px;
        transition: all 0.2s ease-out;
        &:hover {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }

    &.row-action-btn {
      .custom-btn {
        background-color: #b5b5b5;
        color: #222;
        padding: 8px 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }

    &.row-actions {
      // min-width: 262px;
      .action-btns-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .reports-btn,
      .edit-btn {
        background-color: #b5b5b5;
        color: #222;
        padding: 7px 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .delete-btn {
        background-color: $main-app-color;
        padding: 10px 7px;
        border-radius: 12px;
        display: grid;
        place-items: center;
        cursor: pointer;
        svg {
          width: 18px;
          height: 14px;
        }
      }
    }

    &.row-circled-btn {
      .circled-btn {
        width: 40px;
        height: 40px;
        span {
          font-size: 18px;
        }
      }
    }
  }

  // sorting
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $main-app-color !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: #bbb !important;
  }
}

.ant-select-rtl {
  width: 100%;
}


.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.popover-answers-wrap {
  display: grid;
  grid-gap: 8px;
  list-style-type: disc;
  padding-right: 22px;
  max-width: 362px;
}

.ant-popover-inner-content {
  max-width: 280px;
}

.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  line-height: 34px !important;
}

.notification__item--success {
  background-color: lighten($main-app-color, 10%);
  // border: 0;
  border-left-width: 5px;
  .notification__close {
    background-color: #fff;
    width: 22px;
    height: 22px;

    &::after {
      color: $main-app-color;
      font-size: 20px;
      top: 32%;
    }
  }
}
.notification__item--danger {
  background-color: lighten($main-app-color, 10%);
  // border: 0;
  border-left-width: 5px;
  .notification__title {
    color: #fff;
  }
  .notification__close {
    background-color: #fff;
    width: 22px;
    height: 22px;

    &::after {
      color: #dc3545;
      font-size: 20px;
      top: 32%;
    }
  }
}

.notification__item--success {
  background-color: lighten($main-app-color, 10%);
  // border: 0;
  border-left-width: 5px;
  .notification__close {
    background-color: #fff;
    width: 22px;
    height: 22px;

    &::after {
      color: $main-app-color;
      font-size: 20px;
      top: 32%;
    }
  }
}
.notification__item--danger {
  background-color: lighten($main-app-color, 10%);
  // border: 0;
  border-left-width: 5px;
  .notification__title {
    color: #fff;
  }
  .notification__close {
    background-color: #fff;
    width: 22px;
    height: 22px;

    &::after {
      color: #dc3545;
      font-size: 20px;
      top: 32%;
    }
  }
}

.tawk-mobile {
  background-color: moccasin;
}

.invoice-details-wrap {
  td,
  th {
    padding: 2px !important;
    font-size: 14px;
  }
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered > .ant-table-container {
  border-color: #ddd !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  background-color: #eee !important;
  -webkit-print-color-adjust: exact;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa !important;
  -webkit-print-color-adjust: exact;
}

.ant-table-thead > tr > th {
  background-color: transparent;
  color: #000;
}

.ant-descriptions-item-content,
.ant-descriptions-item-label {
  padding: 4px 12px !important;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row {
  border-color: #ddd !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered > .ant-table-container {
  border-color: #888 !important;
}

.ant-table-thead > tr > th {
  background-color: transparent;
  color: #000;
}

//
.v2-invoice-table {
  table {
    border-bottom: 1px solid #888 !important;
    // min-height: 60%;
    // table-layout: fixed !important;
  }
  // tr {
  //   max-height: 42px !important;
  //   line-height: 42px !important;
  // }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0 !important;
    // max-height: 42px !important;
  }
}

.ant-notification-notice-with-icon {
  cursor: pointer;
}
.ant-table{
    .ant-table-content{
      @include tablet{
        overflow-x: scroll;
      }
      
    }
}

.ant-select{
  @include mediaLgMax{
    width: 100% !important;
  }
}
.range-picker-wrap{
  @include mobile{
    width: 90% !important;
  }
  @include mediaLgMax{
    width: 100% ;
  }
}