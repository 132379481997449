@import '../../../scss/config';

.settings-categories-page {
  .page-title {
    h2 {
      @include mediaLg {
        font-size: 42px;
      }
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    @include mediaLg {
      grid-template-columns: 2.9fr 1fr;
    }
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
