@import '../../scss/config';

.main-lang-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  // border-right: 2px solid rgba(0, 0, 0, 0.2);
  span {
    @include mediaLg {
      width: 32px !important;
      height: 24px !important;
      cursor: pointer !important;
    }
  }
}
