@import '../../../scss/config';

.view-transaction{
    .view-transaction-content{
        div{
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
        }
    }
    .journal-amount{
        background-color: $main-app-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        padding: 10px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        h3{
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

        }
    }
}