@import '../../../scss/config';

.custom-shared-form {
  .Journals-form-body {
    // justify-content: flex-start;
    background-color: #f6f5f8;
    padding: 20px;

    .ant-select-selector {
      line-height: 60px;
      border: 0 !important;
      border-radius: 12px !important;
      background-color: transparent;
      .ant-select-selection-placeholder {
        align-self: center !important;
      }

      input {
        height: 62px !important;
        border-radius: 12px !important;
        -webkit-border-radius: 12px !important;
        -moz-border-radius: 12px !important;
        -ms-border-radius: 12px !important;
        -o-border-radius: 12px !important;

        &::placeholder {
          color: black !important;
          align-self: center !important;
        }
      }
    }
    @include tablet {
      // flex-direction: column;
    }

    .li-fields {
      .Journals-select-label-wrap {
        width: 240px;
        height: 62px;
        @include tablet {
          width: 100%;
          height: 70px;
        }
        @include mobile {
          width: 100%;
          height: 62px;
        }
      }
      .Journals-custom-select-wrap {
        width: 100%;
        height: 100%;
        select {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          padding: 6.5px 11px;
          border: none;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
    }

    .field-input-wrapper {
      width: 200px;
      height: 62px;
      border-radius: 12px;
      @include tablet {
        width: 100%;
        height: 70px;
      }
      @include mobile {
        width: 100%;
        height: 62px;
      }
      .field-input {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        padding: 6.5px 11px;
        border: none;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    .col {
      align-self: center;
      .icon {
        font-size: 20px;
      }
    }
  }
  .filed-textarea-wrapper {
    width: 100%;
    // padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
    textarea {
      min-width: 100%;
      min-height: 100px;
      max-width: 100%;
      max-height: 120px;
      background-color: #f6f5f8;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      padding: 6.5px 11px;
      border: none;
      border-radius: 12px;
      resize: both;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
  .editor-wrapper {
    // margin-top: 14px;
    .editor-title {
      color: rgba(#000000, 0.7);
      margin-bottom: 8px;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .toolbarClassName {
      direction: ltr;
    }
    .wrapperClassName {
      margin-bottom: 12px;
      background-color: #eeeeee;
      border-radius: 5px;
      .editorClassName {
        padding: 0 18px;
        min-height: 180px;
      }
    }
  }
}

.Journals-select-label-wrap-payment {
  width: 100%;
  height: 62px;
  margin-bottom: 25px;
  @include tablet {
    width: 100%;
    height: 70px;
  }
  @include mobile {
    width: 100%;
    height: 62px;
  }
  .Journals-custom-select-wrap {
    width: 100%;
    height: 100%;
    select {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      color: rgba(0, 0, 0, 0.85);
      background-color: #f6f5f8;
      font-size: 16px;
      padding: 6.5px 11px;
      border: none;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}
.debit-input{
  input{
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}
}
