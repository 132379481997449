@import '../../../scss/config';
.taxs-settings-wrap {
  display: grid;
  gap: 12px;
  margin-bottom: 22px;
  justify-content: start;
  // @include mediaXlg {
  //   grid-auto-flow: column;
  // }
  .taxs-boxes-wrap {
    display: grid;
    justify-content: start;
    align-content: start;
    gap: 2px;
  }
  .purchases-sales-boxs-wrap {
    display: flex;
    gap: 2px;
  }
  .system-settings-details-wrap {
    .ant-descriptions-item-content,
    .ant-descriptions-item-label {
      padding: 7px 12px !important;
    }
  }
}
