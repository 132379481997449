@import '../../../scss/config';
.installment-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
  }

  .store-new-supplier-form {
    .form-body {
      display: grid;
      grid-gap: 4px 32px;

      .select-label-wrap,
      .text-field-label-wrap,
      .text-area-label-wrap {
        .label-p {
          margin-bottom: 8px;
          padding: 0 6px;
          color: #555;
        }
        position: relative;
        .unit-p {
          position: absolute;
          left: 0;
          font-size: 14px;
          color: #888;
          padding: 0 6px;
          bottom: 5px;
        }
      }

      .custom-select-wrap {
        display: grid;
        background-color: $main-bg-color;
        height: 62px;
        border-radius: 12px;
        position: relative;
        margin-bottom: 28px;

        .select-option-label {
          position: absolute;
          pointer-events: none;
          height: 62px;
          display: grid;
          place-items: center;
          margin-right: 14px;
          .icon-wrap {
            padding: 8px 10px;
            height: 42px;
            background-color: #fff;
            display: grid;
            place-items: center;
            border-radius: 8px;
          }
        }

        .ant-select {
          input {
            height: 62px !important;
          }
          .ant-select-selector {
            height: 62px !important;
            border: 0 !important;
            border-radius: 12px;
            background-color: transparent;
            padding-right: 58px !important;
          }
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            line-height: 58px !important;
          }
          .ant-select-arrow {
            svg {
              height: 10px;
              path {
                fill: #555;
              }
            }
          }
        }

        &.without-icon {
          .ant-select {
            .ant-select-selector {
              padding-right: 11px !important;
            }
          }
        }
      }

      .form-text-field {
        background-color: $main-bg-color;
        border: 0;
        height: 62px;
        border-radius: 12px;

        .ant-input-prefix {
          padding: 8px 10px;
          height: 42px;
          background-color: #fff;
          display: grid;
          place-items: center;
          border-radius: 8px;
          margin: auto;
          margin-left: 8px;
        }
        input {
          background-color: transparent !important;
        }
      }
      .form-text-area {
        background-color: $main-bg-color;
        border: 0;
        border-radius: 12px;
      }

      .country-city-region-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
      }

      .post-box-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }
    }

    .building-number-street-name {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      gap: 12px;
    }

    .submit-btn {
      background-color: $main-app-color;
      color: #fff;
      border-radius: 10px;
      padding: 10px 18px;
      border: 0;
      outline: 0;
      min-height: 52px;
      min-width: 132px;
      max-width: 182px;
      justify-self: end;
      margin-top: 14px;
    }
  }
}
