$main-app-color: #2e4652;
$main-text-color: #000;
$secondary-text-color: #8b8b8b;
$main-bg-color: #f6f5f8;

// accounting colors
$level-one-color: #f1c40f;
$level-two-color: #e17055;
$level-three-color: #00b894;
$level-four-color: #6c5ce7;
$level-five-color: #f1f2f3;

$headerMd-height: 62px;
$headerLg-height: 72px;
$headerXlg-height: 82px;
$headerXXlg-height: 92px;

@mixin mainAppBarHeight {
  height: $headerMd-height;
  @include mediaLg {
    height: $headerLg-height;
  }
  @include mediaXlg {
    height: $headerXlg-height;
  }
  @include mediaXXlg {
    height: $headerXXlg-height;
  }
}

@mixin minusMainAppBarHeight {
  top: -$headerMd-height;
  @include mediaLg {
    top: -$headerLg-height;
  }
  @include mediaXlg {
    top: -$headerXlg-height;
  }
  @include mediaXXlg() {
    top: -$headerXXlg-height;
  }
}

$app-header-z-index: 99;
$backdrop-z-index: 100;
$dropDownMenu-z-index: 200;
$focusedTextArea-z-index: 300;
$miniDropdown-z-index: 400;
$modalWrapper-z-index: 500;
$postModalWrapper-z-index: 499;

@mixin customPaper {
  border-radius: 4px;
  .myPaperHeader {
    padding: 14px 16px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    grid-gap: 6px;
    font-size: 20px;
    border-radius: 4px 4px 0 0;
  }

  &.black {
    .myPaperHeader {
      background-color: #151515;
      color: $black-primary-color;
    }
  }
  &.light {
    .myPaperHeader {
      background-color: $white-primary-color;
      color: #fff;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// ::selection {
// 	background-color: rgba(#3fd4e2, 0.4);
// 	color: #424242;
// }
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

a {
  color: $main-text-color;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@font-face {
  font-family: 'DINNEXTLTARABIC-LIGHT';
  src: url(../assets/fonts/DINNEXTLTARABIC-LIGHT.woff) format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'DINNEXTLTARABIC-Regular';
  src: url(../assets/fonts/DINNextLTArabic-Regular.woff) format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTArabic-Bold';
  src: url(../assets/fonts/DINNEXTLTARABIC-BOLD.woff) format('woff');
  font-style: normal;
}

@mixin bodyFont-ar {
  font-family: 'DINNextLTArabic-Regular' !important;
}

@mixin headingFont-ar {
  font-family: 'DINNextLTArabic-Bold';
}
@mixin headingFont-en {
  font-family: 'DINNextLTArabic-Bold';
}
// @mixin bodyFont-en {
// 	font-family: "AndikaNewBasic-Regular" !important;
// }

// @mixin headingFont-ar {
// 	font-family: "Janna-LT-Bold";
// }
// @mixin headingFont-en {
// 	font-family: "AndikaNewBasic-Bold";
// }

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d5d5d5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5f8b9e8f;
    background-color: #b5b5b5;
    border-radius: 10px;

    &:hover {
      background: #aaa;
    }
  }

  // &:hover {
  // 	&::-webkit-scrollbar-track {
  // 		box-shadow: inset 0 0 1px grey;
  // 		background-color: #f7f8fa;
  // 	}

  // 	&::-webkit-scrollbar-thumb {
  // 		background: #ababab;
  // 		background: red;
  // 	}
  // }
}

@mixin sm_mobile {
  @media only screen and (max-width: 376px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mob_tab {
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    @content;
  }
}
@mixin mid_tab {
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    @content;
  }
}

// custom break points
@mixin mediaXsm {
  @media only screen and (min-width: 320px) {
    @content;
  }
}

@mixin mediaSm {
  @media only screen and (min-width: 375px) {
    @content;
  }
}

@mixin mediaSmd {
  @media only screen and (min-width: 576px) {
    @content;
  }
}
@mixin mediaLessSmd {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin mediaMdMax {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
@mixin mediaLgMax {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin mediaLLg {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin mediaXlg {
  // @media only screen and (min-width: 1200px) {
  @media only screen and (min-width: 1366px) {
    @content;
  }
}

@mixin mediaXXlg {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}
@mixin mediaXXXlg {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
@mixin smRange {
  @media only screen and (min-width: 600px) and (max-width: 768px) {
    @content;
  }
}
@mixin mobTab {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

// @mixin mediaXXlg {
//   @media only screen and (min-width: 1600px) {
//     @content;
//   }
// }

.custom-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  // overflow-x: hidden !important;

  @include mediaSmd {
    max-width: 100%;
  }

  @include mediaMd {
    max-width: 810px;
  }

  @include mediaLg {
    max-width: 960px;
  }
  @include mediaLLg {
    // max-width: 1170px;
    max-width: 1300px;
  }

  @include mediaXlg {
    // max-width: 1140px;
    max-width: 1322px;
  }

  @include mediaXXlg {
    max-width: 1340px;
    // max-width: 1280px;
  }
  @include mediaXXXlg {
    max-width: 1540px;
    // max-width: 1280px;
  }
}

@mixin topBottomAnimation {
  animation: topBottomAnimation 3s linear infinite;

  @keyframes topBottomAnimation {
    0%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(6px);
    }
    30% {
      transform: translateY(6px);
    }
    75% {
      transform: translateY(-6px);
    }
    80% {
      transform: translateY(-6px);
    }
  }
}
@mixin bounce_animation {
  animation: bounce_me 3s linear infinite;

  @keyframes bounce_me {
    0%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(3px);
    }
    32% {
      transform: translateY(3px);
    }
    75% {
      transform: translateY(-3px);
    }
    82% {
      transform: translateY(-3px);
    }
  }
}

// page shared styles
.myPage {
  min-height: 100vh;
  padding-top: $headerMd-height;
  @include mediaLg {
    padding-top: $headerLg-height;
  }
}

.pos-navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mobile {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .header-links {
    // margin-right: calc(420px + 22px);
    // margin-right: calc(382px + 12px);
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    button {
      background-color: $main-app-color;
      color: #fff;
      padding: 6px 24px;
      padding-bottom: 8px;
      border-radius: 8px;
      border: 0;
      height: auto;
      min-height: 52px;
      min-height: 44px;
      &:first-of-type {
        grid-row: 1 / -1;
      }
    }
  }
}
