@import '../../scss/config';

.app-layout {
  // display: flex;
  min-height: 100vh;
  padding: 14px 0;
  @include mediaLg {
    padding: 32px 0;
  }
  &.in-help-page {
    padding: 0;
    background-image: url('../../assets/imgs/settings/help-bg.png');
    background-repeat: repeat;
    .layout-content {
      display: block;
    }
  }
  .layout-content {
    // display: flex;
    gap: 12px;
    @include mediaLg {
      gap: 32px;
      display: flex;
    }

    & > [class$='page'] {
      @include mediaLg {
        min-width: 400px;
      }
      flex-grow: 1;
      @include mediaLg {
        margin-top: 18px;
      }
    }
  }
}
