@import '../../scss/config';

.store-warehouses-table-filter {
  display: grid;
  gap: 32px;
  margin: 18px 0;

  .add-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-account-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
      &.add-sub-account {
        background-color: $main-bg-color;
        color: $main-app-color;
        padding: 4px 28px;
        border: 1px solid #000;
      }
    }
  }

  .form-excel-export-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .filter-form {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;
      align-items: center;

      .table-search {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 8px;
        display: grid;
        label {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: 8px 12px;
          height: 40px;
        }
        input {
          border: 0;
          outline: 0;
          margin-right: 8px;
        }
      }

      .entries-wrap {
        display: flex;
        height: 40px;
        gap: 12px;
        align-items: center;
        .ant-select-selector {
          background-color: $main-bg-color;
          height: 100%;
          border-radius: 8px;
          border: 0;
          input {
            height: 100%;
          }
        }
        .ant-select-selection-item {
          line-height: 40px;
        }
        svg {
          width: 8px;
          height: 8px;
          margin-bottom: 1px;
          path {
            fill: #333;
          }
        }
        .select-title {
          color: #888;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .filter-range-picker {
        background-color: $main-bg-color;
        border-radius: 8px;
        border: 0;
        height: 44px;
      }
      .filter-submit-btn {
        background-color: $main-bg-color;
        border: 1px solid rgba(#000, 0.8);
        height: 36px;
        padding: 2px 28px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.1s ease-out;
        &:hover {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }
    .excel-export-btn {
      cursor: pointer;
      img {
        height: 44px;
        border-radius: 8px;
      }
    }
  }
}
.clients-page .custom-filter-add-section{
 
  @include mobile{
    flex-direction: column;
    width: 100% !important;
    .add-section{
       align-self: center;
    }
  }

  @include mob_tab{
     flex-wrap: wrap;
  }


 .custom-table-fitler{
  @include mediaMdMax{
    align-self: flex-start;
   }

 }
 .custom-table-fitler {

  @include mobile{
    width: 100%;
  }
  .form-excel-export-wrap{
    @include mobile{
      flex-wrap: nowrap;
      width: 100%;

      .filter-form{
        width: 100%;

        .table-search{
          width: 100%;
        }
      }
    }
      @include mediaMdMax{
          display: flex !important;
          flex-direction: row;

          .filter-form{
            display: block !important;
          }
      }

      
  }
 }
  

  .preview-download-upload{
     @include mediaMdMax{
       align-self: center !important;
     }
  }
}