@import '../../../scss/config';

.view-balance{
    .view-balance-title{
        background-color: aliceblue;
        padding: 10px;
    }
    .view-balance-content{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        // gap: 15px;
        div{
            background-color: aliceblue;
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 10px;
            width: 100%;
            padding: 10px 15px;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
}
    }
}
