
@import '../../../scss/config';


.create-receipt-form {
  .prev-new-client-wrap {
    align-items: center;
    gap: 32px;
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .new-client-btn {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    & > svg {
      height: 52px;
      width: 52px;
    }
  }

  .product-id-quantity-wrap {
    grid-template-columns: 1fr 1.5fr !important;
    gap: 12px;
    .q-return-q-price-wrapper {
      display: flex;
      gap: 18px;
    }
    .desc-tax-wrap {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 12px;
    }
  }


}
.newPriceContainer{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 10px;

   @include mediaMdMax{
     // flex-direction: column;
    //  align-items: start;


     /*  .add-new-field-btn{
          margin-top: 20px;
      } */
   }

   @media only screen and (max-width: 400px){
    flex-direction: column;
    align-items: start;

    .add-new-field-btn{
        margin-top: 20px;
    } 
 }

   .fields-array-ul-wrapper{
      border-top:1px solid rgba(0, 0, 0, .1);

      @include mediaMdMax{
          width: 100%;
       }
   }

  
}
