@import './config';

html {
  &[lang='ar'] {
    .bold-font {
      @include headingFont-ar;
      color: $main-app-color;
    }
  }
  &[lang='en'] {
    .bold-font {
      @include headingFont-ar;
      color: $main-app-color;
    }
  }
}
// ltr classes
.app.app-ltr {
  .antd-custom-table {
    .ant-table-thead {
      & > tr:first-child th:first-child {
        border-radius: 12px 0 0 12px !important;
      }
      & > tr:first-child th:last-child {
        border-radius: 0 12px 12px 0 !important;
      }
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none !important;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1.6em;
      background-color: #bbb;
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
    }

    tbody > tr > td:first-child {
      border-radius: 12px 0 0 12px;
    }
    tbody > tr > td:last-child {
      border-radius: 0 12px 12px 0;
    }
    .ant-table-thead > tr > th {
      text-align: center !important;
    }
  }
  .system-settings-page {
    .text-field-label-wrap {
      .form-text-field {
        padding-left: 10px;
      }
    }
  }

  .bills-slick-slider .slick-slide .bills-slider-card {
    direction: ltr;
  }

  .new-supplier-modal
    .store-new-supplier-form
    .form-body
    .form-text-field
    .ant-input-prefix {
    margin-right: 8px !important;
    margin-left: 0 !important;
  }

  .search-notifications-container .search-notifications-profile-wrap .main-app-bar-menu-btn{
    margin-left: 0;
  }
}
.modal-ltr {

  .ant-modal-content {

    .ant-modal-close {
      left: auto !important;
      right: 0 !important;
    }
  }

  .ant-table-cell {
    text-align: center;
  }
}

.subTable-ltr {
  .ant-table-tbody > tr > td,
  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    text-align: center !important;
  }
}
//
.page-head-wrap {
  display: grid;
  grid-template-columns: 2fr 1fr !important;
  align-items: center;
  grid-gap: 22px;

  @include tablet {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  @include mobile {
    grid-gap: 15px !important;
  }

  .search-accounts-wrap {
    display: grid;
    align-content: space-between;
  }
}
.top-section-wrap {
  display: grid;
  grid-template-columns: 1fr 1.2fr !important;
  //align-items: center;
  grid-gap: 22px;
  @include tablet {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
  }
  .search-accounts-wrap {
    display: grid;
    align-content: space-between;
  }
  .search-wrap {
    width: 100%;
  }
}
.search-wrap {
  width: 100%;
}
.system-logo{
  object-fit: contain !important;
}
.custom-slick-slider {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    font-size: 16px;
    width: 38px;
    height: 38px;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 50%;
    outline: none;
    background: transparent;
    background-color: #fff;
    display: grid;
    place-items: center;
    z-index: 1;
    &::before {
      content: '';
    }
    .btn-content {
      position: relative;
      display: flex;
      place-content: center;
      align-items: center;
      svg {
        width: 11px;
        height: 11px;
      }
    }
  }
  .slick-prev {
    left: 12px;
    right: auto;
  }
  .slick-next {
    left: calc(12px + 52px);
  }
}

.shared-custom-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
  }
}

.custom-shared-form {
  .form-body {
    display: grid;
    grid-gap: 4px 32px;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .before-after-wrap,
    .before-after-wrap-without-discount {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 8px;
      line-height: 1;
      .after-wrap {
        display: grid;
        flex-wrap: wrap;
        border-radius: 5px;
        align-content: start;
        min-width: 132px;
        .after-title {
          color: #555;
          margin-bottom: 8px;
          text-align: center;
        }
        .after-value {
          padding: 8px 12px;
          height: 62px;
          background-color: #888;
          color: #fff;
          border-radius: 12px;
          display: grid;
          place-items: center;
          font-size: 20px;
          direction: ltr;
        }
      }
    }
    .before-after-wrap-without-discount {
      .after-wrap {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-top: 8px;
        .after-value {
          height: 52px;
        }
      }
    }
    .select-label-wrap,
    .text-field-label-wrap,
    .text-area-label-wrap {
      .label-p {
        margin-bottom: 8px;
        padding: 0 6px;
        color: #555;
      }
      position: relative;
      .unit-p {
        position: absolute;
        left: 0;
        font-size: 14px;
        color: #888;
        padding: 0 6px;
        bottom: 5px;
      }
    }

    .product-cat-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px;
    }

    .custom-select-wrap {
      display: grid;
      background-color: $main-bg-color;
      height: 62px;
      border-radius: 12px;
      position: relative;
      margin-bottom: 28px;

      .select-option-label {
        position: absolute;
        pointer-events: none;
        height: 62px;
        display: grid;
        place-items: center;
        margin-right: 14px;
        .icon-wrap {
          padding: 8px 10px;
          height: 42px;
          background-color: #fff;
          display: grid;
          place-items: center;
          border-radius: 8px;
        }
      }

      .ant-select {
        input {
          height: 62px !important;
        }
        .ant-select-selector {
          height: 62px !important;
          border: 0 !important;
          border-radius: 12px;
          background-color: transparent;
          // padding-right: 58px !important;
        }
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          line-height: 58px !important;
          max-width: 332px;
        }
        .ant-select-arrow {
          svg {
            height: 10px;
            path {
              fill: #555;
            }
          }
        }
      }

      // &.without-icon {
      //   .ant-select {
      //     .ant-select-selector {
      //       padding-right: 11px !important;
      //     }
      //   }
      // }
      &.multiple-select-option {
        .ant-select {
          input {
            height: 22px !important;
          }
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            height: 36px !important;
            line-height: 20px !important;
            background-color: #fff;
            padding: 4px;
            border-radius: 4px;
            font-size: 15px;
            .ant-select-selection-item-content {
              padding-bottom: 3px !important;
            }
          }
        }
        .ant-select-selection-item-remove > .anticon {
          vertical-align: -0.6em;
        }
        .ant-select-selection-placeholder {
          background-color: transparent !important;
        }
      }
    }
    .form-text-field {
      background-color: $main-bg-color;
      border: 0;
      height: 62px;
      border-radius: 12px;

      .ant-input-prefix {
        padding: 8px 10px;
        height: 42px;
        background-color: #fff;
        display: grid;
        place-items: center;
        border-radius: 8px;
        margin: auto;
        margin-left: 8px;
      }
      input {
        background-color: transparent !important;
      }
    }
    .form-text-field-count {
      height: 50px;
      text-align: center;
    }
    .form-text-area {
      background-color: $main-bg-color;
      border: 0;
      border-radius: 12px;
      padding: 12px 14px;
      min-height: 100px;
      max-height: 300px;
    }

    .country-city-region-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;

      @include smRange {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mobile {
        grid-template-columns: 1fr;
      }
    }

    .date-picker-wrap {
      .label-p {
        margin-bottom: 8px;
        padding: 0 6px;
        color: #555;
      }
      .ant-picker {
        padding: 8px 10px;
        height: 42px;
        background-color: $main-bg-color;
        border: 0;
        height: 62px;
        border-radius: 12px;
        display: grid;
        place-items: center;
        border-radius: 8px;
        // margin: auto;
        margin-left: 8px;
      }
      input {
        background-color: transparent !important;
      }
    }

    .post-box-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    .product-img-wrap {
      h3 {
        margin-bottom: 8px;
        padding: 0 6px;
        color: #555;
      }
    }

    .editor-wrapper {
      .editor-title {
        color: #666;
        margin-bottom: 8px;
        font-size: 1rem;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .toolbarClassName {
        direction: ltr;
      }
      .wrapperClassName {
        margin-bottom: 4px;
        background-color: #f5f5f5;
        .editorClassName {
          padding: 0 18px;
          min-height: 180px;
        }
      }
    }

    .cheque-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px;
    }
    .product-desc-feilds {
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid #e0e2e7;
      box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
      padding: 15px;
    }
    .fields-array-ul-content {
      // background-color: #fff;
      // border-radius: 8px;
      // border: 1px solid #E0E2E7;
      // box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.10);
      // padding: 10px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .fields-array-ul {
      margin-top: 12px;
      display: grid;
      gap: 18px 32px;
      h3 {
        margin-bottom: 10px;
      }
      .field-comp {
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e0e2e7;
        box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
        padding: 10px;

        .field-delete-li-products {
          display: grid;
          grid-template-columns: auto auto auto;
          gap: 12px;
          // background-color: #fff;
          // border-radius: 8px;
          // border: 1px solid #E0E2E7;
          // box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.10);
          // padding: 10px;
          @include mobile {
            grid-template-columns: 40% 40% 5%;
            align-items: flex-end;
            gap: 15px;
          }
          .form-text-field-complex {
            width: 50px;
            height: 50px;
            background-color: transparent;
            text-align: center;
          }
          .btn-process {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: #f9f9fc;
            height: 60px;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            button {
              border: none;
              background-color: transparent;
            }
            .ant-form-item {
              margin-bottom: 0px !important;
            }
          }

          .field-icon {
            height: 62px;
            background-color: #1bee95;
            padding: 8px 16px;
            display: grid;
            place-items: center;
            img {
              height: 30px;
            }
          }
          .delete-field-btn {
            align-self: center;
            // margin-top: 8px;
          }
        }
      }
      .field-delete-li {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 12px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e0e2e7;
        box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
        padding: 10px;
        @include mobile {
          grid-template-columns: 40% 40% 5%;
          align-items: flex-end;
          gap: 15px;
        }

        @include mediaMdMax {
          grid-template-columns: 100% !important;
        }
        .form-text-field-complex {
          width: 50px;
          height: 50px;
          background-color: transparent;
          text-align: center;
        }
        .btn-process {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background-color: #f9f9fc;
          height: 60px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          button {
            border: none;
            background-color: transparent;
          }
          .ant-form-item {
            margin-bottom: 0px !important;
          }
        }

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }
      .field-delete-li-variants {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        gap: 12px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e0e2e7;
        box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
        padding: 10px 5px 0px;
        @include mobile {
          grid-template-columns: 40% 40% 5%;
          align-items: flex-end;
          gap: 15px;
        }
        .form-text-field-complex {
          width: 50px;
          height: 50px;
          background-color: transparent;
          text-align: center;
        }
        .btn-process {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background-color: #f9f9fc;
          height: 60px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          button {
            border: none;
            background-color: transparent;
          }
          .ant-form-item {
            margin-bottom: 0px !important;
          }
        }

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }
      .field-delete-li-products {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .field-delete-li-ingredients {
        grid-template-columns: 1fr 1fr 1fr;
        @include mobile {
          grid-template-columns: 1fr 1fr;
        }
      }
      .bill-field-delete-li {
        background-color: #efefef;
        padding: 18px 20px;
        padding-bottom: 0;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 42px;
        gap: 32px;
        @include mobile {
          display: flex;
          flex-direction: column;
        }
        input,
        .ant-select-selector,
        .receipt-date-picker {
          background-color: #fff !important;
        }

        .product-id-quantity-wrap,
        .warehoues-expiration-date-wrap,
        .service-name-prices-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr 0.5fr;
          gap: 22px;
          .custom-date-input {
            align-self: start;
          }
        }
        .product-id-quantity-wrap {
          grid-template-columns: 1fr 0.5fr 0.5fr;
          gap: 12px;
          @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .q-return-q-price-wrapper {
              align-items: flex-end;
            }
          }
          &.isReturn {
            grid-template-columns: 1fr !important;

            .q-return-q-price-wrapper {
              display: flex;
              gap: 18px;
            }
          }
          .desc-tax-wrap {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 12px;
          }
        }

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }

      &.with-border {
        margin-bottom: 15px;
        // padding-bottom: 32px;
        // border-bottom: 1px solid rgba(#000, 0.2);
      }
      &.without-padding {
        padding-bottom: 0;
      }
    }
    .fields-array-ul-update {
      margin-top: 12px;
      display: grid;
      gap: 18px 32px;
      h3 {
        margin-bottom: 10px;
      }
      .field-comp {
        // background-color: #fff;
        // border-radius: 8px;
        // border: 1px solid #E0E2E7;
        // box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.10);
        // padding: 10px;

        .field-delete-li-products {
          display: grid;
          grid-template-columns: auto auto;
          gap: 12px;
          // background-color: #fff;
          // border-radius: 8px;
          // border: 1px solid #E0E2E7;
          // box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.10);
          // padding: 10px;
          @include mobile {
            grid-template-columns: 40% 40% 5%;
            align-items: flex-end;
            gap: 15px;
          }
          .form-text-field-complex {
            width: 50px;
            height: 50px;
            background-color: transparent;
            text-align: center;
          }
          .btn-process {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: #f9f9fc;
            height: 60px;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            button {
              border: none;
              background-color: transparent;
            }
            .ant-form-item {
              margin-bottom: 0px !important;
            }
          }

          .field-icon {
            height: 62px;
            background-color: #1bee95;
            padding: 8px 16px;
            display: grid;
            place-items: center;
            img {
              height: 30px;
            }
          }
          .delete-field-btn {
            align-self: center;
            // margin-top: 8px;
          }
        }
      }
      .field-delete-li {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 12px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e0e2e7;
        box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
        padding: 10px;
        @include mobile {
          grid-template-columns: 40% 40% 5%;
          align-items: flex-end;
          gap: 15px;
        }
        .form-text-field-complex {
          width: 50px;
          height: 50px;
          background-color: transparent;
          text-align: center;
        }
        .btn-process {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background-color: #f9f9fc;
          height: 60px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          button {
            border: none;
            background-color: transparent;
          }
          .ant-form-item {
            margin-bottom: 0px !important;
          }
        }

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }
      .field-delete-li-variants {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        gap: 12px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e0e2e7;
        box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
        padding: 10px 5px 0px;
        @include mobile {
          grid-template-columns: 40% 40% 5%;
          align-items: flex-end;
          gap: 15px;
        }
        .form-text-field-complex {
          width: 50px;
          height: 50px;
          background-color: transparent;
          text-align: center;
        }
        .btn-process {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background-color: #f9f9fc;
          height: 60px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          button {
            border: none;
            background-color: transparent;
          }
          .ant-form-item {
            margin-bottom: 0px !important;
          }
        }

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }
      .field-delete-li-products {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .field-delete-li-ingredients {
        grid-template-columns: 1fr 1fr 1fr;
        @include mobile {
          grid-template-columns: 1fr 1fr;
        }
      }
      .bill-field-delete-li {
        background-color: #efefef;
        padding: 18px 20px;
        padding-bottom: 0;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 42px;
        gap: 32px;
        @include mobile {
          display: flex;
          flex-direction: column;
        }
        input,
        .ant-select-selector,
        .receipt-date-picker {
          background-color: #fff !important;
        }

        .product-id-quantity-wrap,
        .warehoues-expiration-date-wrap,
        .service-name-prices-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr 0.5fr;
          gap: 22px;
          .custom-date-input {
            align-self: start;
          }
        }
        .product-id-quantity-wrap {
          grid-template-columns: 1fr 0.5fr 0.5fr;
          gap: 12px;
          @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .q-return-q-price-wrapper {
              align-items: flex-end;
            }
          }
          &.isReturn {
            grid-template-columns: 1fr !important;

            .q-return-q-price-wrapper {
              display: flex;
              gap: 18px;
            }
          }
          .desc-tax-wrap {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 12px;
          }
        }

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }

      &.with-border {
        margin-bottom: 15px;
        // padding-bottom: 32px;
        // border-bottom: 1px solid rgba(#000, 0.2);
      }
      &.without-padding {
        padding-bottom: 0;
      }
    }

    .product-img-wrap {
      padding-bottom: 12px;
      margin-bottom: 12px;
      // border-bottom: 1px solid rgba(#000, 0.2);
    }

    .text-input-wrap,
    .select-wrap {
      display: grid;
      .label-wrap {
        display: grid;

        .label-p {
          margin-bottom: 8px;
        }
      }
      input,
      select {
        height: 62px;
        border-radius: 8px;
        background-color: $main-bg-color !important;
        border: 0;
        padding: 10px 18px;
        outline: 0;
        transition: all 0.2s ease-out;
        &:focus {
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
      }
      select {
        appearance: none;
        // background-image: url(../../assets/imgs/icons/down-arrow-icon-red.png);
        background-image: url(../assets/imgs/icons/solid-dropdown.svg);
        background-repeat: no-repeat;
        // background-position: calc(100% - 18px) center;
        background-position: 18px center;
        background-size: 10px;
      }
   /*    &.error-wrap {
        input {
          // border-color: #a61d24;
        }
      } */
    }

    .add-new-field-btn {
      font-weight: bold;
      text-transform: capitalize;
      background-color: $main-app-color;
      color: #fff;
      border-radius: 10px;
      padding: 10px 18px;
      border: 0;
      outline: 0;
      min-height: 44px;
      // margin-top: 22px;
      width: fit-content;
      cursor: pointer;
    }

    .total-fields-price {
      display: flex;
      align-items: center;
      gap: 18px;
      padding: 4px 12px;
      margin: 8px 0;
      background-color: #444;
      padding: 4px 22px;
      color: #fff;
      border-radius: 12px;
      .total-value {
        min-height: 52px;
        display: grid;
        place-items: center;
        font-size: 20px;
        direction: ltr;
        line-height: 2;
      }
    }

    .text-field-wrap {
      position: relative;
      &.with-eye-icon {
        input {
          padding-left: 42px !important;
        }
      }
      .eye-icon-btn {
        position: absolute;
        left: 14px;
        top: 18px;
        cursor: pointer;
        padding: 4px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .radios-wrap {
      margin-bottom: 8px;
      .radios-group-title {
        margin-bottom: 8 px;
        padding: 0 6 px;
        color: #555;
      }
      .labels-wrap {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        gap: 22px;
        justify-content: start;
      }
      .radio-input-label {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 8px;
        .label-span {
          font-size: 16px;
          color: rgba(#000, 0.5);
        }
        input {
          margin-bottom: 4px;
          opacity: 0;
          width: 0;
          height: 0;
        }
        svg {
          fill: rgba(#000, 0.5);
        }

        &.selected {
          svg {
            fill: $main-app-color;
          }
        }
      }
    }

    // .purchase-order-radios {
    //   margin-top: 22px;
    // }

    .radios-field-array-wrap {
      border-bottom: 1px solid rgba(#000, 0.1);
      margin-bottom: 22px;
      &.no-border {
        border-bottom: 0;
      }
      &.border-top {
        border-top: 1px solid rgba(#000, 0.1);
        padding-top: 18px;
      }

      .ingredients-add-btn-wrap {
        @include mediaMdMax {
          grid-template-columns: 100% !important;

          .date-picker-wrap {
            margin-bottom: 20px;
          }
        }
      }

      .ant-form-item-control {
        .ant-radio-group {
          @include mediaMdMax {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0;
          }
        }
      }

      .radios-wrap {
        .labels-wrap {
          @include mediaMdMax {
            grid-auto-flow: row !important;
            margin: 10px 0;
          }
        }
      }
    }

    .custom-picker-wrap {
      display: grid;
    }
    .custom-date-input {
      display: grid;
      .label-span {
        margin-bottom: 8px;
        padding: 0 6px;
        color: #555;
      }
      input {
        height: 62px;
        border-radius: 8px;
        background-color: $main-bg-color !important;
        border: 0;
        padding: 10px 18px;
        padding-right: 4px;
        outline: 0;
        transition: all 0.2s ease-out;
        &:focus {
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
      }
    }

    .receipt-date-picker {
      background-color: #f6f5f8 !important;
      height: 62px;
      border-radius: 8px;
      input {
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
    .from-to-warehouse-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    .error-p {
      color: lighten(red, 10%);
      font-size: 14px;
      text-align: right;
    }
  }
  .supplier-name-view {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 22px;
    font-size: 18px;
    .supp-name {
      background-color: #eee;
      padding: 6px 22px;
      padding-bottom: 8px;
      border-radius: 12px;
      font-size: 20px;
    }
  }
  .submit-btn {
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    justify-self: end;
    margin-top: 22px;

    @include mediaMdMax {
      margin: auto;
    }
  }
}

.custom-filter-add-section,
.custom-filter-add-section1 {
  margin-top: 52px;
  margin-bottom: 10px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: end;
  gap: 22px;
  // @include tablet {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: flex-start;
  // }
  /*  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  } */
  /*   @include mediaMdMax {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  } */
  &.custom-filter-add-section1 {
    grid-auto-flow: row;
    gap: 12px;

    .add-section {
      .add-btn {
        padding: 9px 32px;
        border-radius: 12px;
        min-height: 48px;
      }
    }
  }

  .add-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
/*
    @include smRange {
      margin: auto;
    } */
    @include mobile {
      //  grid-auto-flow: row;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-auto-flow: unset;
      margin: 0 !important;
    }

    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      min-height: 40px;
      display: grid;
      align-items: center;
      // @include mobile {
      //   width: 120px;
      // }
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
    }
  }
}

.custom-table-fitler {
  display: flex;
  align-items: flex-end;
  gap: 22px;

  .form-excel-export-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 22px;

    @include tablet {
      flex-wrap: nowrap;
    }
    @include mobile {
      flex-wrap: wrap;
      justify-content: center;
    }
    @include smRange {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: normal;
    }

    .filter-form {
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
      align-items: flex-end;

      @include mediaLgMax {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 22px;
      }
      @include mobile {
        grid-template-columns: 1fr !important;
      }

      .table-search {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 8px;
        display: grid;
        width: 200px;

        label {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: 8px 12px;
          height: 40px;
        }
        input {
          border: 0;
          outline: 0;
          margin-right: 8px;
        }
      }

      .entries-wrap {
        display: flex;
        height: 40px;
        gap: 12px;
        align-items: center;

        @include mediaMdMax {
          display: grid;
          grid-template-columns: 50% 50%;
        }
        .ant-select-selector {
          background-color: $main-bg-color;
          height: 100%;
          border-radius: 8px;
          border: 0;
          input {
            height: 100%;
          }
        }
        .ant-select-selection-item {
          line-height: 40px;
        }
        svg {
          width: 8px;
          height: 8px;
          margin-bottom: 1px;
          path {
            fill: #333;
          }
        }
        .select-title {
          color: #888;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
      .receipt-filter-option-wrap {
        display: grid;
        gap: 4px;
        .ant-select-selector {
          background-color: $main-bg-color;
          height: 44px;
          height: 100%;
          border-radius: 8px;
          border: 0;
          input {
            height: 100%;
          }
          &::after {
            line-height: 44px !important;
          }
        }
        .ant-select-selection-item {
          line-height: 44px;
        }
        svg {
          width: 8px;
          height: 8px;
          margin-bottom: 1px;
          path {
            fill: #333;
          }
        }
        .select-title {
          color: #888;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

     /*  &.receipt-filter-form {
        // display: flex;
        // grid-auto-flow: column;
        // .filter-submit-btn {
        //   align-self: end;
        // }
      } */

      .filter-range-picker {
        background-color: $main-bg-color;
        border-radius: 8px;
        border: 0;
        height: 40px;
        @include mobile {
          width: 100%;
        }
      }
      .filter-submit-btn {
        background-color: $main-bg-color;
        border: 1px solid rgba(#000, 0.8);
        height: 36px;
        padding: 2px 28px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.1s ease-out;
        &:hover {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }
    .excel-btn-wrap {
      display: grid;
      align-items: end;
      align-self: end;

      @include mediaMdMax {
        align-self: start;
      }
    }
    .excel-export-btn {
      cursor: pointer;
      align-self: flex-end;
      img {
        height: 44px;
        border-radius: 8px;
      }
    }
  }
}

.desc-wrap {
  width: 150px;
  margin: 0px auto;
  text-wrap: wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  text-align: center;
}

.app.app-ltr {
  .ant-modal-close {
    background: #000;
  }
  .antd-custom-table {
    .ant-table-thead {
      & > tr:first-child th:first-child {
        border-radius: 12px 0 0 12px !important;
      }
      & > tr:first-child th:last-child {
        border-radius: 0 12px 12px 0 !important;
      }
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none !important;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1.6em;
      background-color: #bbb;
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
    }

    tbody > tr > td:first-child {
      border-radius: 12px 0 0 12px;
    }
    tbody > tr > td:last-child {
      border-radius: 0 12px 12px 0;
    }
    .ant-table-thead > tr > th {
      text-align: center !important;
    }
  }
}

/* .app.app-rtl .antd-custom-table {
  // background: red;
}
// .ssfsfsf .ant-modal-close-x.ltr{
// background: #000;

// } */
.login-input input{
  direction: rtl;
}
html[lang='en'] .login-input input{
  direction: ltr;
}
html[lang='ar'] .ant-modal-content {
  .radios-wrap .labels-wrap .radio-input-label {
    direction: ltr;
  }
}
html[lang='en'] .ant-modal-content {
            .note-modal-box{
direction: ltr;
}
  direction: ltr;
  div,
  span,
  li,
  ul {
    direction: ltr;
  }

  .supplier-details-wrap {
    margin-right: auto;
    width: 100%;
  }
  .radios-wrap .labels-wrap .radio-input-label {
    direction: rtl;
  }
  .ant-modal-close {
    left: auto !important;
    right: 0 !important;
  }
  .ant-table-cell {
    text-align: center;
  }
}

html[lang='en'] .ant-form-item-control-input .ant-input-prefix {
  margin-right: 8px !important;
  margin-left: 0 !important;
}
html[lang='en']
  .new-supplier-modal
  .store-new-supplier-form
  .form-body
  .form-text-field
  .ant-input-prefix {
  margin-right: 8px !important;
  margin-left: 0 !important;
}
html[lang='en'] .ant-modal-content .antd-custom-table {
  .ant-table-thead tr:first-child th:first-child {
    border-radius: 12px 0 0 12px !important;

    // & > tr:first-child th:last-child {
    //         border-radius: 0 12px 0 12px !important ;

    // }
  }

  .ant-table-thead tr:first-child th:last-child {
    border-radius: 0 12px 12px 0 !important;

    // & > tr:first-child th:last-child {
    //         border-radius: 0 12px 0 12px !important ;

    // }
  }
  tbody {
    & > tr > td {
      &:first-child {
        border-radius: 12px 0 0 12px !important;
      }
      &:last-child {
        border-radius: 0 12px 12px 0 !important;
      }
    }
  }
}

html[lang='en'] .table-search input {
  margin-left: 8px !important;
  margin-right: 0 !important;
}
html[lang='en'] .selected-inovice-modal .circled-btn{
  margin-left: 18px;
  margin-right: 0 !important;
}

html[lang='en']  .model-header-pos{
  padding: 0 30px 0 0 ;
  .circled-btn{
    width: 130px;
  }
}
html[lang='en']  .pos_main_swiper{
  direction: ltr;
}
html[lang='en']  .pos-products-wrap .not-available-tag{
 width: 84px !important;
 border-radius: 4px 0 0 4px;
 right: 0;
}
/* html[lang='en']  .pos-products-wrap  .p-sale-price .price-span{
  left: 0 !important;
  border-radius: 0 4px 18px 0 !important;
} */