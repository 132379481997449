@import '../../../scss/config';

.settings-warehouses-details-page {
  .ant-descriptions-rtl {
    @include mobile {
      width: 95%;
      margin: 0 auto;
    }
    @include sm_mobile {
      width: 90%;
      margin: 0 auto;
    }
  }
  .ant-table-wrapper-rtl .ant-table table {
    @include mobile {
      width: 95%;
      margin: 0 auto;
    }
    @include sm_mobile {
      width: 90%;
      margin: 0 auto;
    }
  }

  .models-title {
    @include mobile {
      margin-right: 10px;
    }
    @include sm_mobile {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-right: 20px;
    }
  }

  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;
    }

    .page-title {
      @include mobile {
        margin-right: 10px;
      }
      @include sm_mobile {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-right: 20px;
      }
    }

    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
