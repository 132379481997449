@import '../../../scss/config';

.installments-page {
  .saleInvoiceInstallmentsPage-table-wrapper {
    @include mobile {
      width: 100%;
      max-height: 550px !important;
      overflow: scroll;
    }
  }
}
