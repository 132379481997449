@import '../../scss/config';

.archive-calendar-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .calendar-wrap {
    margin-top: 52px;
  }
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  text-align: center;
}
.rbc-event-content {
  padding: 8px;
  color: #fff;
}

.reservation-modal {
  .ant-modal-body {
    display: grid;
  }
  .invoice-print-btn {
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    justify-self: center;
    margin-top: 18px;
    cursor: pointer;
  }
}
