@import '../../../scss/config';

.settings-warhouses-page {
  .table-wrapper {
    @include mobile {
      width: 96%;
      margin: 0 auto;
      overflow: scroll;
    }
  }

  h2 {
    @include mobile {
      margin-right: 10px;
    }
  }
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 1fr 1.3fr !important;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
