/* Start-Global */
:root {
  --main-background-color: #4a4379;
  --main-background-color2: #92b1ff;
  --main-font-color2: #e2ddff;
  --main-font-color: #e2ddff;
}

/* Start-container  */

/* start  */
/* .box-all.container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DINNextLTArabic-Regular' !important;
} */
/* .box-all{
  display: flex;
  flex-direction: column;
}
.box-all.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px;
  height: 90vh;
  font-family: 'Almarai', sans-serif;
  box-sizing: border-box;
} */
.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.box-all.container .content {
  width: 100%;
  max-width: 700px;
  height: 100%;
  min-height: 500px;
  background: #2e4652;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  gap: 20px;
  color: var(--main-font-color);
}
.box-all.container .content h2 {
  color: var(--main-font-color);
  font-family: 'DINNextLTArabic-Regular' !important;

  text-transform: capitalize;
  font-weight: 600;
}
.box-all.container .header-box {
  line-height: 40px;
}
.box-all.container .header-box h2 {
  font-weight: 700;
}

.box-all.container .content .phone-box {
  /* margin-top: 10px; */
  gap: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.box-all.container .content .bar-code-box {
  /* margin-top: 10px; */
  gap: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}
.box-all.container .content .bar-code-box .box-head {
  /* margin-top: 10px; */
  gap: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 17px;
  color: var(--main-font-color);
  text-transform: capitalize;
}
.box-all.container .content .bar-code-box .code {
  margin-top: 30px;
  background: var(--main-font-color);
  border-radius: 15px;
}

.qr-btn {
  background: var(--main-font-color);
  margin-top: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.share-btn {
  background: var(--main-font-color);
  margin-top: 30px;
  width: 120px;
  height: 60px;
  border-radius: 10px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.share-btn span {
  color: #000;
}
.qrcode-modal button {
  background-color: #2e4652;
  color: #fff !important;
}
.qrcode-modal .code,
.qrcode-modal .home-content {
  margin-top: 0px;
}
.qrcode-modal .share-btn span {
  color: #fff;
}
.qrcode-modal .home-content .content {
  background-color: unset;
  padding: 0px;
}
.qrcode-modal .home-content .content .bar-code-box h2 {
  color: #2e4652;
}
