
@import '../../scss/config';

.bread-container {
   font-size: 20px;
  .main-page-bread {
    color: rgba(0, 0, 0, 0.45);
    &:hover {
      color: #082434;
    }
  }
  .active-link {
    font-size: 24px;
    color: #082434;
    font-weight: 500;
  }

  @include mediaLgMax{
     font-size: 17px;

     .active-link{
        font-size: 20px;
     }
  } 


  @include mobile{
    font-size: 14px;

    .active-link{
       font-size: 17px;
    }
  }
}
