@import '../../scss/config';

.search-notifications-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: space-between;
  @include tablet {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 10px;
  }
  .search-notifications-profile-wrap {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    height: max-content;
    align-items: center;
    justify-content: end;
    grid-gap: 10px;
    @include tablet {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 95%;
      margin: 0px 0px 20px 0px;
    }
    @include mobile {
      margin: 0px 10px;
    }
    @include sm_mobile {
      margin: 0px 20px;
    }
    .main-app-bar-menu-btn {
      width: 42px;
      height: 42px;
      margin-left: auto;
      display: grid;
      place-items: center;
      margin-bottom: 14px;
      border: 0;
      outline: 0;
      border-radius: 50%;
      cursor: pointer;
      background-color: $main-app-color;
      @include mediaLg {
        display: none;
      }
    }
    .main-app-search {
      border: 1px solid rgba(#000, 0.2);
      border-radius: 8px;
      display: grid;

      label {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: 8px 12px;
      }
      input {
        border: 0;
        outline: 0;
        margin-right: 8px;
      }
    }

    .shift-timer-wrap {
      font-size: 18px;
      color: $main-app-color;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 5px;
      border-left: 2px solid rgba(0, 0, 0, 0.2);
      padding-left: 18px;
      svg {
        margin-top: 4px;
        width: 20px;
        height: 20px;
      }
    }

    .notifications-profile-wrap {
      display: grid;
      grid-auto-flow: column;
      height: max-content;
      align-items: center;
      justify-content: space-evenly;

      .settings-btn {
        cursor: pointer;
        // padding-top: 4px;
        // padding-bottom: 4px;
        display: grid;
        place-items: center;
        padding: 8px;
        border-radius: 5px;
        transition: all 0.2s ease-out;
        svg {
          width: 18px;
          height: 18px;
          path {
            fill: $main-app-color;
          }
        }

        &.clicked {
          background-color: $main-app-color;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .main-app-notifications {
      display: grid;
      border-left: 2px solid rgba(#000, 0.1);
      margin-left: 14px;
      padding-left: 22px;
      margin-right: 22px;
      padding-top: 4px;
      padding-bottom: 4px;

      a {
        display: grid;
        position: relative;
        width: fit-content;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: $main-app-color;
          }
        }
        .active-notifications {
          background-color: #ff8f8f;
          border: 2px solid #fff;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          top: -6px;
          left: -1px;
        }
      }
    }

    .avatar-wrapper {
      padding-right: 22px;
      margin-right: 12px;
      display: grid;
      button {
        height: fit-content;
        padding: 0;
        background-color: transparent;

        .anticon-caret-down {
          color: #d8d8d8;
        }
      }
      position: relative;
      &::after {
        content: '';
        width: 2px;
        background-color: rgba(#000, 0.2);
        height: 68%;
        position: absolute;
        right: 0;
        top: 18%;
      }
    }
  }

  .settings-dropdown-wrap {
    .ant-dropdown-arrow {
      border-top-color: $main-app-color !important;
      border-left-color: $main-app-color !important;
    }

    .settings-dropdown-ul {
      background-color: $main-app-color !important;
      border-radius: 8px !important;
      overflow: hidden;
      padding: 12px 0;
      .settings-menu-link {
        display: grid;
        padding: 0;
        background-color: transparent !important;
        a {
          color: rgba(#fff, 0.8);
          display: grid;
          text-align: center;
          padding: 8px 28px;
          transition: none;
          &:hover {
            background-color: lighten($main-app-color, 8%);
            box-shadow: 0 1px 2px rgba(#fff, 0.1);
            color: #fff;
          }
        }
      }
    }
  }
}
