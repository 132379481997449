@import '../../scss/config';

.profile-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .img-form-wrap {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 52px;
    & > img {
      align-self: center;
      // margin-top: 122px;
    }
    @include tablet {
      width: 90%;
      margin: 20px auto;
      display: flex;
      flex-direction: column-reverse;
      .settingsImg {
        z-index: 0;
      }
    }

    @include mobile {
      grid-template-columns: 1fr;
      .settingsImg {
        display: none;
      }
    }
  }
}
