@import '../../../scss/config';

.view-accounts{
    .view-accounts-center{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .view-accounts-center button{
        background-color: #b5b5b5;
        color: #222;
        background-color: #2e4652;
        color: #fff;
        padding: 9px 16px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
        cursor: pointer;
        font-size: 15px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        -o-border-radius: 7px;
}
    .view-accounts-content{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        // gap: 15px;
        div{
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
            width: 50%;
            padding: 10px;
            background-color: aliceblue;
        }
    }
}
.spin-tree-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
}