@import '../../scss/config';

.login-page {
  background-color: #fff;
  // min-height: 100vh;
  height: 100vh;
  // padding: 42px 0;
  // @include mediaLg {
  //   padding: 92px 0;
  // }
  display: grid;
  // place-items: center;
  .page-wrapper {
    display: grid;
    @include mediaLLg {
      grid-template-columns: 42% 1fr;
    }
    grid-gap: 32px;
    .website-link {
      display: block;
    }
    .img-wrap {
      display: grid;
      position: relative;
      .extra-logo-img {
        img {
          width: 192px;
        }
        position: absolute;
        top: 22px;
        right: 22px;
        z-index: 1;
        max-width: 192px;
      }
      .main-img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: bottom center;
      }

      .text-section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 18px;
        width: 74%;
        max-width: 552px;
        h1 {
          font-size: 42px;
        }
        h1,
        p {
          color: #fff;
        }
        .sp-line {
          background-color: #fff;
          width: 52px;
          height: 4px;
          border-radius: 99px;
          margin: 18px auto 12px auto;
        }
      }
    }

    .form-top-level-wrapper {
      display: grid;
      justify-items: center;
    }
  }
}
