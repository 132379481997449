@import '../../scss/config';

@mixin sharedPadding {
  padding: 10px 14px;
}
$sharedCellWidth: 148px;

.selected-inovice-modal {
  .models-wrap {
    width: 100%;
    max-width: 110%;
  }
  .ant-modal-body {
    padding-top: 12px;
  }
  .invoice-details-wrap {
    display: grid;
    gap: 16px;
    @include mobile {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .invoice-heading-wrap {
      @include mobile {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        .qr-code-label-wrap {
          align-self: center !important;
        }
      }
    }

    .inovice-nums-qrcode {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 22px;
      align-items: center;
      .nums-wrap {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 4px;
      }
      .inovice-num {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        grid-template-columns: $sharedCellWidth auto;
        font-size: 18px;
        border-bottom: 1px solid rgba(#000, 0.2);
        .num-title {
          border-left: 1px solid rgba(#000, 0.2);
          @include sharedPadding;
        }
        .num-itself {
          @include sharedPadding;
        }
      }

      .inovice-dates {
        display: grid;

        .date-wrap {
          display: grid;
          grid-auto-flow: column;
          justify-content: start;
          grid-template-columns: $sharedCellWidth auto;
          gap: 6px;
          font-size: 16px;
          border-bottom: 1px solid rgba(#000, 0.2);
          &:last-of-type {
            border-bottom: 0;
          }
          .date-title {
            border-left: 1px solid rgba(#000, 0.2);
            @include sharedPadding;
          }
          .date-value {
            @include sharedPadding;
          }
        }
      }

      .qr-code-wrap {
        display: grid;
        justify-content: end;
        .qr-code-img {
          max-width: 100%;
          width: 180px;
        }
      }
    }
  }

  .models-title {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    padding: 4px 12px !important;
  }
}

.selected-inovice-modal {
  .circled-btn {
    margin-right: 18px;
    width: 38px;
    height: 38px;
    span {
      line-height: 40px;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}
