@import '../../scss/config';

.main-app-bar-wrap {
  min-width: 124px;
  max-width: 124px;
  min-height: 88vh;
  @include tablet {
    position: absolute;
  }
  @include mobile {
    min-width: 100px;
    max-width: 100px;
    min-height: 40vh;
    height: auto;
    grid-gap: 15px;
    position: absolute;
  }
  @include sm_mobile {
    min-width: 80px;
    max-width: 80px;
    padding: 10px 0px;
    height: auto;
  }
  // @include mediaLg {
  //   height: 94vh;
  // }
  align-self: start;
  background-color: $main-app-color;
  border-radius: 10px;
  padding: 22px 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  box-shadow: -16px 18px 20px rgba(#000, 0.16);
  grid-gap: 32px;
  z-index: 999;
  display: none;
  position: fixed;
  // right: 22px;
  top: 62px;
  @include mediaLg {
    position: sticky;
    top: 32px;
    right: auto;
    display: grid;
    border-radius: 18px;
    padding: 32px 0;
  }

  &.visible-lg {
    display: grid;
  }

  .side-menu-logo {
    display: grid;
    place-items: center;
    img {
      width: 55px;
    }
  }

  .app-bar-ul {
    align-self: center;
    display: grid;
    align-content: start;
    grid-gap: 32px;
    // justify-items: center;
    @include mobile {
      grid-gap: 10px;
    }

    .link-li {
      display: grid;
      color: rgba(#fff, 0.6);
      padding: 0 8px;
      position: relative;
      @include sm_mobile {
        padding: 0 2px;
      }

      .nav-link {
        display: grid;
        color: #b5b5b5;
        justify-items: center;
        text-align: center;
        cursor: pointer;
        svg {
          width: 26px;
          height: 26px;
          @include mobile {
            width: 22px;
            height: 22px;
          }
          @include sm_mobile {
            width: 15px;
            height: 15px;
          }
        }
        .name-span {
          @include sm_mobile {
            font-size: 13px;
          }
        }
        &.active-link {
          color: #fff;
        }
      }

      // .sub-menu-transition-group-enter {
      //   opacity: 0;
      //   transform: scale(0.9);
      // }
      // .sub-menu-transition-group-enter-active {
      //   opacity: 1;
      //   transform: translateX(0);
      //   transition: opacity 300ms, transform 300ms;
      // }
      // .sub-menu-transition-group-exit {
      //   opacity: 1;
      // }
      // .sub-menu-transition-group-exit-active {
      //   opacity: 0;
      //   transform: scale(0.9);
      //   transition: opacity 300ms, transform 300ms;
      // }
      .sub-menu-transition-group-enter {
        opacity: 0;
        transform: scale(0.96) translateY(52px);
      }
      .sub-menu-transition-group-enter-active {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;
      }
      .sub-menu-transition-group-exit {
        opacity: 1;
      }
      .sub-menu-transition-group-exit-active {
        opacity: 0;
        transform: scale(0.96);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: ease-out;
      }

      .sub-menu-ul {
        background-color: $main-app-color;
        border-radius: 8px 0 0 8px;
        position: absolute;
        top: 0;
        right: 100%;
        // width: 172px;
        min-width: 152px;
        width: max-content;
        padding: 8px;
        z-index: 10;
        display: grid;
        gap: 8px;

        .sub-menu-li {
          text-align: center;
          display: grid;
          a {
            display: grid;
            color: rgba(#fff, 0.6);
            padding: 8px 18px;
            &:hover {
              color: #fff;
            }
          }
        }
      }
      .sub-menu-ul-ltr {
        background-color: $main-app-color;
        border-radius: 0 8px 8px 0;
        position: absolute;
        top: 0;
        left: 100%;
        // width: 172px;
        min-width: 152px;
        width: max-content;
        padding: 8px;
        z-index: 10;
        display: grid;
        gap: 8px;

        .sub-menu-li {
          text-align: center;
          display: grid;
          a {
            display: grid;
            color: rgba(#fff, 0.6);
            padding: 8px 18px;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .settings-signout-wrap {
    // margin-top: 52px;
    cursor: pointer;
    display: grid;
    justify-items: center;
    grid-gap: 32px;
    .nav-link {
      display: grid;
      color: #b5b5b5;
      justify-items: center;
      &.active-link {
        color: #fff;
      }
    }
  }
}
