@import '../../../scss/config';

.archive-calendar-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .calendar-wrap {
    margin-top: 52px;
  }
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  text-align: center;
}
.rbc-event-content {
  padding: 8px;
  color: #fff;
}
