@import '../../../scss/config';

.newPriceContainer{

    display:flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom:30px ;
    @include mediaMdMax{
      //  flex-direction: column;
       // align-items: start;


        /* .add-new-field-btn{
            margin-top: 20px;
        } */
     }
     @media only screen and (max-width: 400px){
        flex-direction: column;
        align-items: start;

        .add-new-field-btn{
            margin-top: 20px;
        } 
     }
     .newPriceContainerCon{
        border-top:1px solid rgba(0, 0, 0, .1);

        @include mediaMdMax{
            width: 100%;
         }
     }

  
}