.water-mark {
  opacity: 0.15;
  font-size: 120px;
  width: fit-content;
  text-align: center;
  text-wrap: nowrap;
  letter-spacing: 6px;
  color: 'black';
  background: '#ccc';
  position: absolute;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  right: 50%;
  top: 50%;
  transform: rotateZ(-45deg) translate(50%, 50%);
  z-index: 0;
  p {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
}
