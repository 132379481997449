@import '../../../scss/config';

.form-excel-export-wrap{
    .filter-form{
        @include mediaLgMax{
            display: flex !important;
            flex-wrap: wrap;
            align-items: flex-end;
            width: 100%;
        }
        @include mobile{
            display: grid !important;
            grid-template-columns: repeat(3,1fr);

            .table-search{
                grid-column: 1/4;
            }
        }
        .entries-wrap{
            @include mediaMdMax{
                display: flex !important;
            }
        }
        
    }
}