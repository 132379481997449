@import '../../scss/config';

.pos-whole-receipt-wrap {
  background-color: $main-app-color;
  border-radius: 14px;
  padding: 10px;
  min-height: 80vh;
  display: grid;
  grid-template-rows: 1fr auto;
  position: sticky;
  top: 22px;
  // width: 420px;
  min-width: 382px;
  top: 32px;
  // max-height: 90vh;
  max-height: 70vh;
  overflow-y: auto;
  @include custom-scrollbar;
  .pos-receipt-wrap {
    background-color: #fff;
    border-radius: 10px;
    display: grid;
    grid-template-rows: auto 1fr;
    .store-logo-name-wrap {
      align-self: start;
      display: grid;
      justify-items: center;
      padding: 22px 22px 0 22px;
      img {
        max-width: 80%;
        width: 122px;
      }
      .store-name {
        margin-top: 12px;
        color: $main-app-color;
        font-size: 20px;
      }
    }

    .empty-cart-wrap {
      display: grid;
      place-content: center;
      place-items: center;
      padding: 22px;
      margin-bottom: 32px;
      p {
        margin-top: 12px;
      }
      svg {
        width: 82px;
        height: 82px;
      }
    }
    .invoice-content {
      .invoice-number {
        margin: 12px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;
        justify-content: center;
        .number-span {
          background-color: #f5f5f5;
          padding: 4px 10px;
          padding-bottom: 6px;
          border-radius: 6px;
          font-size: 16px;
        }
      }

      .system-info {
        margin: 18px;
        padding-bottom: 18px;
        margin-bottom: 0;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 12px 4px;
        color: #444;
        font-size: 15px;
        .cell-wrap {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 2px;
        }
        .info-cell-value {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .invoice-products-table {
        width: 100%;
        padding: 0 10px;
        text-align: right;
        border-collapse: separate;
        border-spacing: 4px 18px;

        thead {
          tr > {
            th:first-of-type {
              min-width: 136px;
              max-width: 136px;
            }
          }
        }
        .invoice-table-cell {
          padding: 2px 12px;
          &.product-name-cell {
            min-width: 136px;
            max-width: 136px;
          }
        }
      }
      .prices-table {
        width: calc(100% - 36px);
        border-top: 1px dashed rgba(#000, 0.3);
        margin: auto;
        text-align: right;
        border-collapse: separate;
        border-spacing: 4px 18px;
        .invoice-table-cell {
          padding: 2px 12px;
        }
        .col-head {
          // min-width: 136px;
          width: 194px;
        }
        .fake-td {
          color: #888;
          width: 75px;
        }
      }
    }

    .grand-total-invoice-price {
      border-top: 1px dashed rgba(#000, 0.3);
      color: $main-app-color;
      margin: 18px;
      margin-top: 0;
      padding-top: 8px;
      display: grid;
      font-size: 22px;
      text-align: center;
      span {
        &:nth-of-type(2) {
          margin-top: 4px;
        }
      }
    }

    .qr-code-img-wrap {
      display: grid;
      place-items: center;
      padding: 18px;
      padding-top: 0;
      margin-top: 18px;
      .qrcode-img {
        width: 192px;
        max-width: 100%;
      }
    }
  }
  .action-btns-wrap {
    padding-top: 12px;
    padding-bottom: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    button {
      padding: 14px 14px;
      border-radius: 20px;
      height: auto;
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
      &:focus {
        border: 1px solid #fff;
      }
      &:hover {
        border-color: #fff;
      }
    }
    .step-btns {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      button {
        color: $main-app-color;
        background-color: #fff;
      }
    }
  }
}
.sucess-btns-wrap {
  display: flex;
  gap: 22px;
}
.success-order-modal {
  display: grid;
  place-items: center;
  .modal-body {
    padding: 18px 14px;
    display: grid;
    place-content: center;
    position: absolute;
    background-color: #f7f8fa;
    width: 94%;
    max-width: 600px;
    min-height: 360px;
    @include mediaLg {
      min-height: 400px;
    }
    box-shadow: 0 12px 14px rgba(#000, 0.1), 0 0 30px rgba(#000, 0.1);
    border-radius: 4px;
    border: 0;
    outline: 0;

    img {
      width: 132px;
      // margin-left: -42px;
    }
    p {
      margin-top: 18px;
      text-align: center;
      font-size: 20px;
    }

    .cart-link {
      background-color: $main-app-color;
      color: #fff;
      width: fit-content;
      justify-self: center;
      margin-top: 18px;
      border-radius: 4px;
      padding: 5px 18px;
      font-size: 15px;
      display: flex;
      gap: 6px;
      align-items: center;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: darken($main-app-color, 8%);
        box-shadow: 0 8px 12px rgba(#000, 0.1);
      }
      svg {
        width: 15px;
        height: 15px;
        path {
          stroke: #fff;
        }
      }
    }
  }
}

.loading-modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000, 0.5);
  display: grid;
  align-content: center;
  .modal-wrap {
    background-color: #fff;
    max-width: 522px;
    height: 212px;
    border-radius: 5px;
    margin: 32px auto;
    display: grid;
    place-items: center;
  }
  .print-link {
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    justify-self: center;
    margin-top: 22px;
    &:hover {
      background-color: $main-app-color;
    }
  }

  .sucess-btns-wrap {
    display: flex;
    gap: 22px;
  }
}