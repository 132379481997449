@import '../../scss/config';

.pos-step-one,
.not-layout-filter {
  .barcode-cats-wrap {
    // margin-top: 22px;
    // background-color: #f5f5f5;
    padding: 8px 12px;
    padding: 0px 6px 0px 4px;
    border-radius: 6px;
    height: max-content;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    transition: all 0.3s ease-out;

    .trible-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 8px;
    }
    .barcode-name-wrap {
      display: grid;
      // grid-template-columns: auto 1fr;
      gap: 8px;
    }

    .client-select-wrap {
      height: 70px;
      height: calc(22px + 32px);
      display: grid;
      grid-template-rows: 24px 42px;
      grid-template-rows: 24px 32px;
    }

    .text-input-wrap,
    .select-label-wrap {
      display: grid;
      .label-p {
        margin-bottom: 4px;
        display: grid;
        font-size: 12px;
      }
      input,
      select {
        height: 42px !important;
        height: 32px !important;
        border-radius: 8px;
        // background-color: #f1f1f1 !important;
        background-color: #fff !important;
        border: 1px solid transparent;
        font-size: 18px;
        font-size: 18px;
        outline: 0;
        transition: all 0.2s ease-out;
        &:focus {
          border-color: rgba(#f1f1f1, 0.1) !important;
          box-shadow: 1px 2px 3px 3px rgba(#fff, 0.03);
          // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
      }
      .ant-select {
        font-size: 14px !important;
      }
      .ant-select-selector {
        height: 42px !important;
        line-height: 42px !important;
        height: 32px !important;
        line-height: 32px !important;
        border: 0 !important;
        border-radius: 4px;
        padding-right: 12px !important;
        // background-color: #f1f1f1 !important;
        background-color: #fff !important;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 38px !important;
        line-height: 28px !important;
      }
      .ant-select-arrow {
        svg {
          height: 10px;
          path {
            fill: #555;
          }
        }
      }

      // &.error-wrap {
      //   input {
      //     border-color: #a61d24;
      //   }
      // }
    }
  }

  .cats-tags-wrap {
    // margin-right: calc(420px + 22px);
    .cats-sub-cats-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;

      .select-label-wrap {
        height: calc(22px + 32px);
        align-items: center;
        // gap: 12px;
      }
    }

    .sub-cats-ul-wrap {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 14px;
      .sub-cat-tag {
        display: none;
        flex-wrap: nowrap;
        align-items: center;
        // padding: 4px 24px;
        border-radius: 4px;
        background-color: #fff;
        font-size: 14px;
        // border: 1px solid rgba(#000, 0.04);
        cursor: pointer;
        transition: all 0.2s ease-out;
        line-height: 1;
        &:hover {
          border-color: rgba(#000, 0.1);
          box-shadow: 0 2px 4px rgba(#000, 0.1);
        }
        .sub-cat-name {
          padding: 8px 8px 10px 8px;
        }
        &.selected {
          display: flex;
          background-color: $main-app-color;
          color: #fff;
        }
        .remove-sub-cat-btn {
          height: 100%;
          padding: 4px 10px 8px 10px;
          font-size: 18px;
          color: #fff;
          background-color: #539a8f;
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }

  .product-barcode-name-forms-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
  }
  .product-barcode-form,
  .product-name-form {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 42px;
    height: 70px;
    height: calc(22px + 32px);

    label {
      display: grid;
      font-size: 12px;
      & > span {
        margin-bottom: 4px;
      }
    }
    input {
      width: 100%;
      height: 55px !important;
      line-height: 55px !important;
      
      font-size: 18px !important;
      padding: 8px 12px;
      border: 1px solid #eee;
      border-radius: 0 4px 4px 0;
      // background-color: #f1f1f1 !important;
      background-color: #fbfbfb !important;

      &:focus {
        border: 0;
        outline: none;
      }
    }
    button {
      align-self: end;
      height: 42px;
      min-width: 42px;
      height: 55px;
      min-width: 55px;
      border: 1px solid #eee;
      outline: 0;
      padding: 4px 12px;
      border-radius: 4px 0 0 4px;
      cursor: pointer;
      background-color: #fbfbfb;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .pos-setp-one-filter-notifications-wrap {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 12px;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: all 0.3s ease-out;
    background-color: #fff;
    .barcode-btn-main-filter-wrapper {
      display: grid;
      grid-template-columns: 1fr;

      .barcode-modal-btn {
        align-self: center;
        background-color: transparent;
        width: 66px;
        height: 66px;
        background-color: #ffc107;
        // background-color: #4caf50;
        border: 0;
        outline: 0;
        display: grid;
        border-radius: 50%;
        place-items: center;
        cursor: pointer;
        margin-left: 12px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .sub-cats-ul-wrap {
    grid-column: 1 / -1;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .sub-cat-tag {
      display: none;
      flex-wrap: nowrap;
      align-items: center;
      // padding: 4px 24px;
      border-radius: 4px;
      background-color: #fff;
      font-size: 14px;
      // border: 1px solid rgba(#000, 0.04);
      cursor: pointer;
      transition: all 0.2s ease-out;
      line-height: 1;
      &:hover {
        border-color: rgba(#000, 0.1);
        box-shadow: 0 2px 4px rgba(#000, 0.1);
      }
      .sub-cat-name {
        padding: 8px 8px 10px 8px;
      }
      &.selected {
        display: flex;
        background-color: $main-app-color;
        color: #fff;
      }
      .remove-sub-cat-btn {
        height: 100%;
        padding: 4px 10px 8px 10px;
        font-size: 18px;
        color: #fff;
        background-color: #539a8f;
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.pos-step-one {
  .step-field-wrap {
    &.stacked {
      max-width: 668px;

      @include mediaXXlg {
        max-width: 885px;
      }
    }
  }
}
