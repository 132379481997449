@import '../../scss/config';

.product-ingredients-wrap {
  display: grid;
  .checkboxs-label {
    color: #444;
    font-size: 18px;
  }

  .checkboxs-button-wrap {
    display: grid;
    justify-content: center;
  }

  .checkbox-quantity-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    margin-top: 8px;
    gap: 18px 52px;
    min-height: 68px;
    color: $main-app-color;
    border-bottom: 1px dashed rgba(#000, 0.2);
    padding-bottom: 12px;
    // &:last-of-type {
    //   border-bottom: 0;
    // }
    &:first-of-type {
      margin-top: 0;
    }
    label {
      margin-bottom: 0 !important;
    }

    .checkbox-label,
    .quantity-label {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 32px;
    }
    .checkbox-label {
      font-size: 18px;
      input {
        height: 18px;
        width: 18px;
      }
      span {
        margin-bottom: 4px;
      }
    }
    .quantity-label {
      font-size: 18px;

      input {
        height: 52px;
        width: 92px;
        border: 1px solid rgba(#000, 0.1);
        border-radius: 8px;
        background-color: #f5f5f5;
        padding: 4px 12px;
        padding-right: 22px;
        padding-bottom: 10px;
        text-align: center;
        outline: 0;
        font-size: 18px;
        color: $main-app-color;
        &:focus {
          border-color: $main-app-color;
        }
      }
    }
  }
  .submit-btn {
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    margin: auto;
    margin-top: 12px;
  }
}
