@import '../../../scss/config';

.excel-download-btns-wrap {
  display: grid;
  gap: 14px;
}

.preview-download-upload,
.excel-download-btn {
  align-self: flex-end;
  background-color: #2e4652;
  color: #fff;
  padding: 5.5px 28px;
  border-radius: 8px;
  min-height: 40px;
  display: grid;
  align-items: center;
  cursor: pointer;


  @media(max-width:768px) {
    align-self: flex-start;
  }

  .text-span{
    text-align: center;
  }
}
.excel-download-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  width: fit-content;
  font-size: 14px;
  .label-span {
    margin-bottom: 6px;
  }
}


.custom-filter-add-section{

  @media only screen and (max-width: 1200px){
     flex-direction: column;
     align-items: start !important;

     .custom-table-fitler{
        margin: 0;
        .form-excel-export-wrap{
          flex-wrap: nowrap !important;

          .filter-form{
            width: 50%;
          }
          .add-btn{
            width: 200px;
          }
        }
     }
  }
  @include mediaMdMax{
      margin: 52px 0 10px 0;
    }
 
  .filter-form{
    @include mediaMdMax{
      width: 100% !important;
    }
  }

  .custom-table-fitler {
    .form-excel-export-wrap {
          .add-btn{

            @include mobile{
              width: auto !important;
            }
             
    
          }
    }
  }
}
