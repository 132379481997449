@import '../../scss/config';

.pos-stepper-form-wrap {
  display: grid;
  // grid-template-columns: 320px 1fr;
  grid-gap: 42px;
  min-height: 70vh;

  .stepper-step {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    .step-main-content {
      display: grid;
      align-content: start;
      gap: 8px;

      // padding-right: calc(420px + 22px);
      // padding-right: calc(382px + 12px);
    }
  }

  .error-p {
    color: #a61d24;
    margin-top: 5px;
  }
}
