@import '../../../scss/config';

.custom-shared-form {
    display: flex;
    flex-direction: column;
    .ant-form-item{
        margin-bottom: 20px !important;
    }
    .custom-select-wrap{
        margin-bottom: 20px !important;
    }
    .add-line-btn{
        background-color: #2e4652;
        color: #fff;
        border-radius: 10px;
        padding: 10px 18px;
        border: 0;
        outline: 0;
        min-height: 52px;
        min-width: 132px;
        max-width: 182px;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        margin-top: 15px;
    }

    .form-body {
        .select-label-wrap {
            margin-bottom: 10px;
        }
    }
    .credit-debit-warning{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        span{
            background-color: #da0d0d9a;
            color: #fff;
            padding: 10px 25px;
            font-weight: 600;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
        }
    }
    .credit-debit-success{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        span{
            background-color: #5abf019a;
            color: #fff;
            padding: 10px 25px;
            font-weight: 600;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
        }
    }
    .journal-total{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .total-credit{
            gap: 10px;
            background-color: #edf0f3;
            padding: 20px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
        }
        .total-debit{
            gap: 10px;
            background-color: #edf0f3;
            padding: 20px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
        }
    }
}
.form-journal-wrapper{
    width: 100%;
    .form-journal-wrapper-row{
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: row;
        gap: 20px;
        input{
            color: brown !important;
        }
        @include mobile {
            flex-direction: column;
        }
        .col{
            display: flex;
            flex-direction: column;
        }
    }
}