@import '../../../scss/config';

.store-conversion-page {
  .text-navbar-wrap {
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    grid-gap: 22px;
  }
  .page-text {
    margin-bottom: 132px;
    h2 {
      font-size: 32px;
    }
    p {
      font-size: 18px;
    }
  }

  .form-img-wrap {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 52px;
    justify-content: space-between;
    & > img {
      max-width: 100%;
      margin-top: 62px;
    }
  }
}

.page-head-wrap {
  @include tablet {
    flex-direction: column-reverse !important;
  }
}

.add-excel-con{
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  gap: 20px;
}