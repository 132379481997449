@import '../../scss/config';

.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  direction: ltr;
  @include tablet {
    width: 92%;
    height: 75vh;
  }
  @include mobile {
    width: 92%;
    height: 65vh;
  }
  @media only screen and (max-width: 400px){
    margin-top: 20px !important;
   }
  .otp-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 20px 10px;
    margin: 0 auto;
    width: 80%;
    min-height: 50%;
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.5);
    background-color: #fff;
    .otp-btn-group{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
    }
    @include tablet {
      width: 80%;
      min-height: 70%;
    }
    @include mobile {
      width: 92%;
      min-height: 70%;
    }

    input {
      width: 40px !important;
      height: 45px !important;
      border: 1px solid $main-app-color;
      margin: 0px 5px;
      font-size: 20px;
      background-color: #f7f8fb;
      border-radius: 5px;



      @media only screen and (max-width: 400px){
          margin-right: 3px !important;
      }

      &:focus {
        outline: none !important;
        border: 1px solid $main-app-color;
        box-shadow: none;
      }
    }

    h2 {
      color: #2e4652;
      font-weight: bold;
      font-size: 1.9rem;
      @include mobile {
        font-size: 1.5rem;
      }
    }
    h3 {
      color: #595f6f;
      font-weight: 700;
      font-size: 1.1rem;
      @include mobile {
        font-size: .8rem;
      }
    }

    p {
      color: #595f6f;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .otp-btn {
      display: flex;
      align-items: center;
      justify-content: center !important;
      width: 40%;
      height: max-content;
      text-align: center;
      border-radius: 10px;
      background-color: $main-app-color;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      padding: 10px;
      margin-top: 20px;
      @include mobile {
        width: 40%;
        height: max-content;
      }
      
    }
    .otp-input-btn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
    }
    .resend-otp-btn{
      font-size: 16px;
      font-weight: 600;
      color: $main-app-color !important;
      background-color: transparent !important;
      border: none !important;
    }
    .remainingtime{
      color: $main-app-color !important;
      font-weight: 600;
    }
    .otp-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      h2 {
        color: #2e4652;
        font-weight: bold;
        font-size: 2rem;
      }

      p {
        color: #595f6f;
        font-weight: bold;
      }
      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
      .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        border: 1px solid $main-app-color;
        border-radius: 10px;
        font-size: 18px;
        &:hover,
        &:focus {
          border: 1px solid $main-app-color;
        }
      }

      .Mui-focused {
        border: none;
      }
      .MuiOutlinedInput-input {
        border: none;
      }

      .otp-btn {
        display: flex;
        align-items: center;
        justify-content: center !important;
        width: 35%;
        height: max-content;
        text-align: center;
        border-radius: 10px;
        background-color: $main-app-color;
        color: #fff;
        font-size: 18px;
        padding: 3px;
        @include mobile {
          width: 50%;
          height: max-content;
        }
      }
    }
  }
}
