@import '../../scss/config';

.pos-step-two {
  .invoice-discount-wrap {
    margin-top: 14px;
    background-color: #f5f5f5;
    border-radius: 18px;
    padding: 18px;
    .discount-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .radios-group-title {
      color: rgba(#fff, 0.5);
      font-size: 22px;
      margin-bottom: 8px;
    }

    .discount-radios-wrap {
      display: flex;
      padding: 12px;
      flex-wrap: wrap;
      gap: 18px;
    }
    .radio-input-label {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 8px 22px;
      padding-bottom: 10px;
      border-radius: 8px;
      cursor: pointer;

      input {
        margin-bottom: 4px;
        opacity: 0;
        width: 0;
        height: 0;
      }

      &.selected {
        background-color: $main-app-color;
        color: #fff;
      }
    }
  }

  .discount-value-label-wrap {
    margin-top: 18px;
    display: flex;
    gap: 8px;
    align-items: center;

    input {
      border: 1px solid rgba(#000, 0.2);
      height: 48px;
      border-radius: 8px;
      padding: 8px 12px;
      outline: 0;
      width: 132px;
      &:focus {
        border-color: rgba(#000, 0.3);
      }
    }
  }
}
