@import '../../scss/config';

.new-product-modal {
  .name-default-barcode-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;

    @include mobile{
      grid-template-columns: 1fr !important;
    }
  }
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
  }

  // .buying-wrap,
  // .discount-units-wrap {
  .buying-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;

    @include mediaMdMax{
      grid-template-columns: 1fr;
    }
  }

  .unit-isWeight-wrapper {
    // grid-column: 1 / -1;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    gap: 22px;
    align-items: center;
  }
}

.product-img-wrap {
  .imgPreview {
    width: fit-content;
    img {
      width: auto;
      max-width: 100%;
      max-height: 380px;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.1);
    }
    position: relative;
    .icon-cancel {
      position: absolute;
      top: -10px;
      left: -10px;
      color: #fff;
      background-color: #222;
      border-radius: 50%;
      font-size: 14px;
      width: 28px;
      height: 28px;
      border: 1px solid rgba(#fff, 0.1);
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }
}

.form-text-tax{
  width: 100%;
  height: 62px;
  background-color: #f6f5f8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 20px;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #244e63 !important;
  border-color: #244e63 !important;
}