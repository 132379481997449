@import '../../../scss/config';

.store-welcome-shared-section {
  background-color: $main-bg-color;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 62% 38%;
  grid-gap: 12px;
  min-height: 174px;
  position: relative;
  width: 100%;
  @include mobile {
    grid-gap: 0px;
  }
  .section-title {
    padding: 22px 42px;
    padding-left: 22px;
    display: grid;
    align-items: center;
    align-content: center;
    .main-title {
      font-size: 32px;
    }
    .sub-title {
      font-size: 18px;
    }
  }
  .hello-img {
    // position: absolute;
    // left: 8px;
    // bottom: 0;
    // width: 38%;
    display: grid;
    place-items: center;
    padding: 8px;
    img {
      width: 100%;
      max-height: 192px;
      object-fit: cover;
      object-position: top center;
      border-radius: 10px;
      @include mobile {
        
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
