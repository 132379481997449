.pos-report-modal {
  top: 50px !important;
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 4px 12px !important;
  }
  .model-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 30px;
    button{
      background-color: #2e4652;
      color: #fff;
      /* padding: 5px 10px; */
      border-radius: 8px;
      font-size: 16px;
      height: 40px !important;
      width: 120px;
      /* line-height: 40px; */
      border: none;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
}
  }
  .end-shift{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
  }
  .end-shift-btn{
    background-color: #2e4652;
    color: #fff;
    /* padding: 5px 10px; */
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    height: 40px !important;
    line-height: 40px !important;
    width: 180px;
    cursor: pointer;
    border: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}
