@import '../../scss/config';

.clients-page {
  .top-section-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .add-btn {
    @include mobile {
      padding: 5px 15px !important;
    }
  }
  .sync-btn{
    background-color: $main-app-color;
    color: #fff;
    padding: 5px 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    // place-items: center;
    cursor: pointer;
  }

  .ClientsPage-table-wrapper {
    @include mobile {
      width: 100%;
      max-height: 550px !important;
      overflow: scroll;
    }
  }

  .custom-filter-add-section .add-section .add-btn {
    width: auto !important;
  }
}
