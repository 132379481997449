@import '../../scss/config';

.categoryswiper-container {
  // display: grid;
  // grid-template-columns: 15% auto;
  align-items: center;
  justify-content: center;
  height: 65px;
  margin-top: 2px;
  margin-bottom: 15px;
  padding: 10px 15px;
  background-color: #2e4652;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-family: 'DINNextLTArabic-Regular' !important;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;

  @include tablet {
    height: 45px;
    gap: 10px;
  }


  .title {
    // font-family: 'Cairo';
    // margin-left: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    align-self: center;
    text-align: center;

    @include tablet {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .category-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    max-width: max-content;
    height: 100%;
    padding: 8px;
    gap: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;


    @include tablet {
      padding: 5px;
      gap: 5px;
    }

    &:hover {
      cursor: pointer;
    }

    .category-name {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: fit-content;

      // width: auto;
      p {
        font-weight: 600;
        font-size: 15px;
        height: 20px;
        line-height: 20px;
        letter-spacing: 1.3px;
        text-align: right;
        color: #000;
        font-family: 'DINNextLTArabic-Regular' !important;

        @include tablet {
          height: 11px;
          line-height: 13px;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }

  .mySwiper {
    width: 100% !important;
    // direction: rtl;
  }

  .swiper {
    width: 100%;
    height: fit-content;
    margin: 0 !important;
    // overflow: auto;
    // overflow-x: hidden;
    display: flex;
    justify-content: stretch;
  }

  .swiper .swiper-wrapper {
    height: auto !important;
  }

  .swiper-slide {
    width: auto !important;
    min-width: 75px;
    // height: 100px;
    font-size: 18px;
    //background: #fff;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .swiper-slide img {
    display: block;
    width: 50px;
    height: 50px;
    object-fit: fill;
  }
}

.swiper-container {
  // width: 95% !important;
  overflow: hidden;
  flex: 1;
  min-width: 390px;
  max-width: 780px;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include tablet {
    max-width: 400px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  height: 100%;
  background-color: #2e4652 !important;
  opacity: 1 !important;
  padding: 5px;
  top: 48%;

  @include tablet {
    top: 71%;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {

  font-size: 24px !important;
  color: white;
}

.swiper-button-prev {
  height: 100%;
  right: 0 !important;
}

.swiper-button-next {
  height: 100%;
  left: 0 !important;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none;
}

