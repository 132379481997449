@import '../../../scss/config';

.settings-system-users-page {
  width: 100%;
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }

  .SettingsSystemUsersPage-table-wrapper {
    @include tablet{
      overflow: scroll;
    }
    @include mobile {
      margin-top: 20px;
      width: 100%;
      overflow: scroll;
    }
  }

  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .toggle-user-status-btn {
    background-color: transparent;
    border: 0;
    outline: 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #ccc;
    padding: 4px 20px 8px 20px;
    color: #323232;
    cursor: pointer;
    &.active {
      background-color: #2a9d94;
      color: #fff;
    }
  }

  .custom-filter-add-section {
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 95%;
      overflow: hidden;
    }
    // .add-section {
    //   .add-btn {
    //     width: 152px;
    //     span {
    //       @include mobile {
    //         font-size: 12px;
    //       }
    //     }
    //   }
    // }
  }
}

.system-users-passwords-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.roles-pos-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}
