@import '../../../scss/config';

@mixin sharedPadding {
  padding: 10px 14px;
}
$sharedCellWidth: 148px;

.selected-inovice-modal {
  .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    @include mobile {
      gap: 10px;
    }
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
    @include mobile {
      width: 100%;
      margin: 0px;
    }
  }
  .invoice-details-wrap {
    @include mobile {
      display: flex !important;
      flex-direction: column;
    }
    .models-wrap {
      @include mobile {
        overflow: scroll;
      }
    }
  }
  .ant-table-container {
    @include mobile {
      border: none !important;
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    .title-wrapper-icons {
      @include mobile {
        display: flex;
        gap: 18px;
      }
    }
  }

  .ant-modal-body {
    padding-top: 12px;
  }
  .invoice-details-wrap {
    // display: grid;
    // gap: 16px;
    // background-image: url('../../../assets/imgs/Watermark.png');

    .inovice-nums-qrcode {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 22px;
      align-items: center;

      .nums-wrap {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 4px;
      }
      .inovice-num {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        grid-template-columns: $sharedCellWidth auto;
        font-size: 18px;
        border-bottom: 1px solid rgba(#000, 0.2);
        .num-title {
          border-left: 1px solid rgba(#000, 0.2);
          @include sharedPadding;
        }
        .num-itself {
          @include sharedPadding;
        }
      }

      .inovice-dates {
        display: grid;

        .date-wrap {
          display: grid;
          grid-auto-flow: column;
          justify-content: start;
          grid-template-columns: $sharedCellWidth auto;
          gap: 6px;
          font-size: 16px;
          border-bottom: 1px solid rgba(#000, 0.2);
          &:last-of-type {
            border-bottom: 0;
          }
          .date-title {
            border-left: 1px solid rgba(#000, 0.2);
            @include sharedPadding;
          }
          .date-value {
            @include sharedPadding;
          }
        }
      }

      .qr-code-wrap {
        display: grid;
        justify-content: end;
        canvas {
          // height: 92px !important;
          // width: 92px !important;
        }
      }
    }
  }

  .models-title {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-descriptions-item-content,
  .ant-descriptions-item-label {
    padding: 4px 12px !important;
  }
}

.selected-inovice-modal {
  .invoice-extra-prices-table {
    td {
      text-align: right;
      padding: 4px 16px;
    }
  }

  .circled-btn {
    margin-right: 18px;
    width: 38px;
    height: 38px;
    @include mobile {
      margin-right: 0px !important;
    }
    span {
      line-height: 40px;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}
