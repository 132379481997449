// @import '../../../../scss/config';
@import '../../../scss/config';

.store-warehouses-table-filter {
  display: grid;
  gap: 32px;
  margin: 18px 0;

  .add-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

  }

  .form-excel-export-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .filter-form {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;
      align-items: center;

      .table-search {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 8px;
        display: grid;
        label {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: 8px 12px;
          height: 40px;
        }
        input {
          border: 0;
          outline: 0;
          margin-right: 8px;
        }
      }

      .entries-wrap {
        display: flex;
        height: 40px;
        gap: 12px;
        align-items: center;
        .ant-select-selector {
          background-color: $main-bg-color;
          height: 100%;
          border-radius: 8px;
          border: 0;
          input {
            height: 100%;
          }
        }
        .ant-select-selection-item {
          line-height: 40px;
        }
        svg {
          width: 8px;
          height: 8px;
          margin-bottom: 1px;
          path {
            fill: #333;
          }
        }
        .select-title {
          color: #888;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .filter-range-picker {
        background-color: $main-bg-color;
        border-radius: 8px;
        border: 0;
        height: 44px;
      }
      .filter-submit-btn {
        background-color: $main-bg-color;
        border: 1px solid rgba(#000, 0.8);
        height: 36px;
        padding: 2px 28px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.1s ease-out;
        &:hover {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }
    .excel-export-btn {
      cursor: pointer;
      img {
        height: 44px;
        border-radius: 8px;
      }
    }
  }
}
.account-statement-filter-form{
  h2{
    margin: 30px 0px 20px; 
  }
  form{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 50% !important;
    flex-direction: column;
    @include mobile {
      width: 100% !important;
    }
    .select-label-wrap{
      height: 50px;
      width: 100% !important;
      @include mobile {
        width: 100% !important;
      }
      
      input{}
      // padding: 5px;
    }
  }
  .statement-select-label-wrap{
    width: 100% !important;
    @include mobile {
    }
  }
  .ant-select-selector {
      width: 200px !important;
      height: 49px !important;
      border-radius: 8px !important;
      -webkit-border-radius: 8px !important;
      -moz-border-radius: 8px !important;
      -ms-border-radius: 8px !important;
      -o-border-radius: 8px !important;
      width: 100% !important;
      @include mobile {
        width: 100% !important;
      }
      .ant-select-selection-placeholder {
        align-self: center !important;
      }
      .ant-select-selection-item {
        align-self: center !important;
        font-size: 15px;
      }
}
  margin: 20px 0px;
}
.account-statement-form{
.range-picker-wrap{
  margin: 0px 0px;
  width: 100% !important;
  @include mobile {
  }
}
.filter-range-picker {
  background-color: $main-bg-color;
  border-radius: 8px !important;
  border: 0;
  height: 50px;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  
  width: 100% !important;
  @include mobile {
  }
}
.filter-submit-btn {
  background-color: #2e4652;
  color: #fff;
  border: none;
  height: 46px;
  padding: 5px 48px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease-out;
  &:hover {
    background-color: $main-app-color;
    color: #fff;
  }
}}