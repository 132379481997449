@import '../../../scss/config';

.app.app-rtl .settings-help-page-wrapper {
  padding: 32px 0;
  min-height: 100vh;
  .page-main-title {
    color: #fff;
    font-size: 32px;
    text-align: center;
    margin-bottom: 32px;
  }
  .page-home-link {
    color: #b5b5b5;
    background-color: rgba(82, 126, 148, 0.329);
    padding: 14px 22px;
    border-radius: 8px 8px 0 0;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin: 0 auto 2px auto;
    text-align: center;
    &:hover {
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .page-links-img-wrapper {
    display: grid;
    grid-template-columns: 20% 1fr;
    gap: 42px;
    .help-page-links {
      background-color: rgba(82, 126, 148, 0.329);
      border-right: 2px solid #2ba097;
      padding: 20px 0;
      // border-radius: 8px 2px 2px 8px;
      border-radius: 0 2px 2px 8px;
      // align-self: start;
      display: grid;
      align-content: start;
      gap: 12px;
      color: #fff;
      li {
        position: relative;
        & > .name-icon-wrap {
          width: fit-content;
          position: relative;
          line-height: 1;
          margin-right: 22px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          border-radius: 22px;
          padding-left: 18px;

          .name-span {
            padding: 10px 18px 16px 0;
          }
        }
        .nested-ul {
          border-right: 2px dashed #536b79;
          align-self: start;
          display: grid;
          align-content: start;
          gap: 14px;
          color: #fff;
          margin-right: 42px;
          padding-top: 22px;
          li {
            & > .name-icon-wrap {
              font-size: 15px;
              .name-span {
                padding: 2px;
              }
            }
          }
        }
        &.selected {
          & > .name-icon-wrap {
            background-color: #2ba097;
            color: #fff;
            .caret-left-icon-wrap {
              transform: rotate(-90deg);
            }
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 100%;
              transform: translateY(-50%);
              width: 22px;
              height: 2px;
              background-color: #2ba097;
            }
          }
          .nested-ul {
            li {
              &.selected {
                & > .name-icon-wrap {
                  background-color: #536b79;
                  .name-span {
                    padding: 8px 18px 12px 0;
                  }
                  &::before {
                    border-top: 2px dashed #536b79;
                    height: 0;
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .page-img-wrap {
      img {
        max-width: 70vw;
        border-radius: 12px;
      }
    }
  }
}

.app.app-ltr .settings-help-page-wrapper {
  padding: 32px 0;
  min-height: 100vh;
  .page-main-title {
    color: #fff;
    font-size: 32px;
    text-align: center;
    margin-bottom: 32px;
  }
  .page-home-link {
    color: #b5b5b5;
    background-color: rgba(82, 126, 148, 0.329);
    padding: 14px 22px;
    border-radius: 8px 8px 0 0;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin: 0 auto 2px auto;
    text-align: center;
    &:hover {
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .page-links-img-wrapper {
    display: grid;
    grid-template-columns: 20% 1fr;
    gap: 42px;
    .help-page-links {
      background-color: rgba(82, 126, 148, 0.329);
      border-left: 2px solid #2ba097;
      padding: 20px 0;
      // border-radius: 8px 2px 2px 8px;
      border-radius: 0 2px 2px 8px;
      // align-self: start;
      display: grid;
      align-content: start;
      gap: 12px;
      color: #fff;
      li {
        position: relative;
        & > .name-icon-wrap {
          width: fit-content;
          position: relative;
          line-height: 1;
          margin-left: 22px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          border-radius: 22px;
          padding-right: 18px;

          .name-span {
            // padding: 10px 18px 16px 0;
            padding: 10px 0px 16px 18px;
          }
        }
        .nested-ul {
          border-left: 2px dashed #536b79;
          align-self: start;
          display: grid;
          align-content: start;
          gap: 14px;
          color: #fff;
          margin-left: 42px;
          padding-top: 22px;
          li {
            & > .name-icon-wrap {
              font-size: 15px;
              .name-span {
                padding: 2px;
              }
            }
          }
        }
        .name-icon-wrap{
.caret-left-icon-wrap {
              transform: rotate(0deg);
            }
        }
        .name-icon-wrap .caret-left-icon-wrap {
              transform: rotate(180deg);
            }

        &.selected {
          & > .name-icon-wrap {
            background-color: #2ba097;
            color: #fff;
            .caret-left-icon-wrap {
              transform: rotate(270deg);
            }
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 100%;
              transform: translateY(-50%);
              width: 22px;
              height: 2px;
              background-color: #2ba097;
            }
          }
          .nested-ul {
            li {
              &.selected {
                & > .name-icon-wrap {
                  background-color: #536b79;
                  .name-span {
                    padding: 8px 0 12px 18px;
                  }
                  &::before {
                    border-top: 2px dashed #536b79;
                    height: 0;
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .page-img-wrap {
      img {
        max-width: 70vw;
        border-radius: 12px;
   }
}
}
}
