@import '../../../scss/config';

.product-barcodes-modal {
  .barcoes-btn {
    display: grid;
    justify-items: center;
  }
  .barcodes-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    svg {
      max-width: 200px;
    }

    .p-name-price p {
      line-height: 18px;
      font-size: 14px;
      text-align: center;
      white-space: noWrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      direction: rtl;
    }
  }
  .print-btn {
    background-color: $main-app-color !important;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    margin-top: 22px;
  }
}
