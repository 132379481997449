@import '../../scss/config';

.new-product-modal {
  .ant-modal-header {
    background-color: #f5f5f5;
  }
  .ant-modal-title {
    font-size: 22px;
  }
  .ant-modal-content {
    margin: 0 auto;
    border-radius: 15px;
    overflow: hidden;

    @include mobile {
      display: flex;
      flex-direction: column;
      margin: 0px auto;
      padding: 0px auto;
    }
  }

  .text-field-label-wrap {
    .text-field-wrap {
      @include mobile {
        width: 100%;
      }
    }
  }

  .buying-wrap,
  .discount-units-wrap {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    gap: 22px;
   /*  @include mobile {
      width: 95%;
      grid-template-columns: 50% 50%;
    } */
    .text-field-wrap {
      width: 100%;
    }
  }
}
.price-tax-cont{
   @include mediaMdMax{
    grid-template-columns: 1fr 1fr !important;
   }

   @include mediaLessSmd{
    grid-template-columns: 1fr !important;
  }
}
.product-type-con{
  display: block !important;
}
.product-img-wrap {
  @include mobile {
    width: 100% !important;
  }

  .default-dropzone-img {
    @include mobile {
      max-width: 100% !important;
    }
  }

  .imgPreview {
    width: fit-content;
    img {
      width: auto;
      max-width: 100%;
      max-height: 380px;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.1);
    }
    position: relative;
    .icon-cancel {
      position: absolute;
      top: -10px;
      left: -10px;
      color: #fff;
      background-color: #222;
      border-radius: 50%;
      font-size: 14px;
      width: 28px;
      height: 28px;
      border: 1px solid rgba(#fff, 0.1);
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }
}
