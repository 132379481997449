@import '../../scss/config';

.installments-page {
  .page-head-wrap {
    @include tablet {
      display: flex;
      flex-direction: column-reverse !important;
      // justify-content: center;
    }
  }

  .UserInstallmentsPage-table-wrapper {
    @include mobile {
      margin-top: 20px;
      width: 100%;
      max-height: 550px !important;
      overflow: scroll;
    }
  }
}
