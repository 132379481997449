// @import '../../../../scss/config';
@import '../../../scss/config';

.store-warehouses-table-filter {
  display: grid;
  gap: 32px;
  margin: 18px 0;

  .add-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

  }

  .form-excel-export-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .filter-form {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;
      align-items: center;

      .table-search {
        border: 1px solid rgba(#000, 0.2);
        border-radius: 8px;
        display: grid;
        label {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: 8px 12px;
          height: 40px;
        }
        input {
          border: 0;
          outline: 0;
          margin-right: 8px;
        }
      }

      .entries-wrap {
        display: flex;
        height: 40px;
        gap: 12px;
        align-items: center;
        .ant-select-selector {
          background-color: $main-bg-color;
          height: 100%;
          border-radius: 8px;
          border: 0;
          input {
            height: 100%;
          }
        }
        .ant-select-selection-item {
          line-height: 40px;
        }
        svg {
          width: 8px;
          height: 8px;
          margin-bottom: 1px;
          path {
            fill: #333;
          }
        }
        .select-title {
          color: #888;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .filter-range-picker {
        background-color: $main-bg-color;
        border-radius: 8px;
        border: 0;
        height: 44px;
      }
      .filter-submit-btn {
        background-color: $main-bg-color;
        border: 1px solid rgba(#000, 0.8);
        height: 36px;
        padding: 2px 28px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.1s ease-out;

        @include mobile{
          margin: 15px auto;
        }
        &:hover {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }
    .excel-export-btn {
      cursor: pointer;
      img {
        height: 44px;
        border-radius: 8px;
      }
    }
  }
}
.balance-filter-form{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  .range-picker-wrap {
    width: 100%;
  }
  @include mediaMdMax{
    display: block !important;
  }
  form{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    @include smRange{
      display: grid;
      grid-template-columns: repeat(2,1fr);
    }
    @include mobile{
      display: flex !important;
      flex-direction: column !important;
    }
    .select-label-wrap{
      height: 50px;
      width: 100%;
      height: 62px !important;
      // padding: 5px;
      .ant-select-selector{
        width: 100% !important;
        @include mobile{
          width: 50% !important;
          margin: auto;
        }
      }
    }
  }
  .date-picker-wrap{
    border: 1px solid #d9d9d9;
    // height: 49px !important;
    border-radius: 8px !important;
    width: 70% !important;
  }
  .ant-select-selector {
      width: 200px !important;
      height: 62px !important;
      line-height: 60px;
      border-radius: 8px !important;
      -webkit-border-radius: 8px !important;
      -moz-border-radius: 8px !important;
      -ms-border-radius: 8px !important;
      -o-border-radius: 8px !important;
      
      .ant-select-selection-placeholder {
        align-self: center !important;
      }
  }
  margin: 20px 0px;
}
.balance-filter-form .range-picker-wrap{
  margin: 20px 0px;

  @include mobile{
    display: flex;
    justify-content: center;
  }
}
.balance-filter-form .filter-range-picker {
  background-color: $main-bg-color;
  border-radius: 8px !important;
  border: 0;
  height: 50px;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.balance-filter-form .filter-submit-btn {
  background-color: #2e4652;
  color: #fff;
  border: none;
  height: 62px;
  padding: 5px 38px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease-out;

   @include mediaMdMax {
        grid-column: 1/3;
        width: 50%;
        margin: auto;
      }
  &:hover {
    background-color: $main-app-color;
    color: #fff;
  }
}

.page-title{

 @include mediaMdMax{
    width: 100%;
    margin-bottom: 20px;
  }
}