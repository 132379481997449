.settings-system-users-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 22px;
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}

.system-users-passwords-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.roles-pos-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}
