@import '../../../scss/config';

.store-Receipt-page,
.installments-page {
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    // grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    // @include tablet {
    //   flex-direction: column-reverse;
    // }
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }
}
.IncomingPurchaseInvoicePage-page-title-page-head-wrap {
  display: grid;
  grid-template-columns: 2.9fr 1fr;
  grid-gap: 22px;
  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
  .search-accounts-wrap {
    display: grid;
    align-content: space-between;
  }
}

.note{
  border: 1px solid #eee;
  padding: 20px;
  p{
    width: 100%;
    text-wrap: wrap;
    word-break: break-word;
    overflow-wrap: break-word;
  }
}
